import { AnchorWallet } from '@solana/wallet-adapter-react'
import { Dispatch, FC, SetStateAction, useCallback, useContext, useMemo } from 'react'
import { AppContext } from '../../../../AppContext'
import { CURRENCY_NAME, DEFAULT_LOCKUP_LENGTH, REWARD_RATES } from '../../../../constants/staking'
import { useTransaction } from '../../../../hooks/useTransaction'
import { TimeContext } from '../../../../pages/staking/Staking'
import { stakeNft, StakeResponse, unstakeNft } from '../../../../requests/staking/programSimpleTransactions'
import { StakedNft, UnstakedNft } from '../../../../types/staking/nfts'
import { unixTimestampToObject } from '../../../../utils/general'
import { getCurrentRateForTimestamps, getRewardForTimestamps, getRewardProgressForTimestamp } from '../../../../utils/local/staking'
import { Paragraph, ParagraphLetterSpacing, ParagraphOpacity, ParagraphSize, ParagraphWeight } from '../../../text/paragraph/Paragraph'
import Button, { ButtonColor, ButtonSize } from '../../button/Button'
import { ReactComponent as DashedCircle } from './dashed_circle.svg'
import { ReactComponent as GoldCoinFlat } from './gold_coin_flat.svg'
import classes from './styles.module.css'
import progress_classes from './progress.module.css'

type NftCardProps = {
  nft: UnstakedNft | StakedNft,
  setNfts: Dispatch<SetStateAction<(UnstakedNft | StakedNft)[]>>
}

const NftCard: FC<NftCardProps> = ({nft, setNfts}) => {

  const currentTimestamp = useContext(TimeContext)
  const { refreshBalance } = useContext(AppContext)

  const stakeBuilder = useCallback(
    (wallet: AnchorWallet) => stakeNft(wallet, [nft as UnstakedNft]), [nft]
  )

  const stake = useTransaction(
    stakeBuilder,
    'Yay! Your 1 Amo was successfully staked!',
    undefined,
    () => nft.status === 'unstaked',
    (response: StakeResponse[]) => setNfts(prev => {
      let upd = prev.map<StakedNft | UnstakedNft>(nft => {
        if (nft.status === 'unstaked') {
            let filtered = response.filter(s => s.mint.toBase58() === nft.mint.toBase58())
            if (!filtered || !filtered.length) return nft
            let stake = filtered[0].stake
            return {
              ...nft,
              stake,
              claimTimestamp: currentTimestamp,
              stakeTimestamp: currentTimestamp,
              unlockTimestamp: currentTimestamp + DEFAULT_LOCKUP_LENGTH,
              status: 'staked'
            }
        }
        else return nft
      })

      return upd
    })
  )

  const unstakeBuilder = useCallback(
    (wallet: AnchorWallet) => unstakeNft(wallet, [nft as StakedNft]), [nft]
  )

  const unstakeCallback = useCallback(
    () => {
      setNfts(prev => {
        let index = prev.findIndex(n => n.mint.toBase58() === nft.mint.toBase58())

        console.log(prev[index])

        let newNft: UnstakedNft = {
          ...prev[index] as StakedNft,
          status: 'unstaked'
        }

        prev[index] = newNft

        console.log(prev[index])

        return [...prev]
      })
      if (refreshBalance) setTimeout(refreshBalance, 5000)
    }, [nft, setNfts, refreshBalance]
  )

  const unstake = useTransaction(
    unstakeBuilder,
    'Yay! Your 1 Amo was successfully unstaked!', 
    unstakeCallback,
    () => nft.status === 'staked'
  )

  const currentReward = useMemo(() => nft.status === 'staked' ?
    getRewardForTimestamps(nft.stakeTimestamp, nft.claimTimestamp, currentTimestamp).toString() :
    '0' 
  , [currentTimestamp, nft])

  const rewardProgress = useMemo(() => {
    if (nft.status !== 'staked') return 'start'
    let progress = getRewardProgressForTimestamp(nft.claimTimestamp, currentTimestamp)
    if (progress < 12.5) return 'start'
    if (progress < 25) return 'first'
    if (progress < 37.5) return 'second'
    if (progress < 50) return 'third'
    if (progress < 62.5) return 'fourth'
    if (progress < 75) return 'fifth'
    if (progress < 87.5) return 'sixth'
    if (progress < 92.5) return 'eighth'
    if (progress < 97.5) return 'end'
    return 'end'
    }, [currentTimestamp, nft])
  
  const currentRate = useMemo(() => nft.status === 'staked' ?
    getCurrentRateForTimestamps(nft.stakeTimestamp, currentTimestamp).toString() :
    REWARD_RATES[0].reward.toString()
  , [currentTimestamp, nft])

  const timestamp = useMemo(() => nft.status === 'staked' ? unixTimestampToObject(currentTimestamp - nft.stakeTimestamp) : { 
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0
  }, [currentTimestamp, nft])

  return (
    <div className={classes.wrapper}>
        <Paragraph classes={classes.name} size={ParagraphSize.MediumLarge}>{nft.name}</Paragraph>
        <div className={classes.image}>
          {
            nft.status === 'staked' ?
            <div className={classes.time_counter}>
              <Paragraph size={ParagraphSize.ExtraLarge} contrast>{
                timestamp.months > 0 ? timestamp.months.toString() : timestamp.days.toString()
              }</Paragraph>
              <Paragraph size={ParagraphSize.ExtraSmall} opacity={ParagraphOpacity.Medium} defaultLineHeight contrast>{
                timestamp.months > 0 ? timestamp.months === 1 ? 'month' : 'months' : 'days'
              }</Paragraph>
            </div> : null
          }
          <img src={nft.image} alt='nft' draggable='false'/>
          {
            nft.status === 'staked' ?
            <div className={classes.reward_counter_wrapper}>
              <GoldCoinFlat className={classes.reward_icon}/>
              <div className={progress_classes.reward_progress + ' ' + progress_classes[rewardProgress]}/>
              <div className={progress_classes.reward_progress_end + ' ' + progress_classes[rewardProgress]}/>
              <DashedCircle className={classes.reward_dashes}/>
              <div className={classes.reward_counter}>
                <Paragraph size={ParagraphSize.MediumLarge} defaultLineHeight weight={ParagraphWeight.Bold}>{
                  currentReward
                }</Paragraph>
                <Paragraph size={ParagraphSize.Little} opacity={ParagraphOpacity.Medium} spacing={ParagraphLetterSpacing.Medium} defaultLineHeight>{CURRENCY_NAME}</Paragraph>
              </div>
            </div> : null
          }
        </div>
        {
          nft.status === 'staked' ?
          <Paragraph classes={classes.reward} size={ParagraphSize.ExtraSmall} defaultLineHeight opacity={ParagraphOpacity.High}>
            <Paragraph size={ParagraphSize.MediumSmall} span weight={ParagraphWeight.Bold} defaultLineHeight>{
              currentRate
            }</Paragraph>
            &nbsp;{CURRENCY_NAME} per day
          </Paragraph>
          :
          <Paragraph classes={classes.reward_large} size={ParagraphSize.MediumLarge} defaultLineHeight weight={ParagraphWeight.Thin}>
            <Paragraph size={ParagraphSize.Large} span>{currentRate}</Paragraph>
            &nbsp;{CURRENCY_NAME} per day
          </Paragraph>
        }
        {
          nft.status === 'staked' && nft.unlockTimestamp > currentTimestamp ?
          <Button color={ButtonColor.Default} disabled size={ButtonSize.Medium} autoWidth>
            Unstake
          </Button>
          : nft.status === 'staked' ?
          <Button onClick={unstake} size={ButtonSize.Medium} autoWidth>
            Unstake
          </Button> :
          <Button onClick={stake} color={ButtonColor.Contrast} size={ButtonSize.Medium} autoWidth>
            Stake
          </Button>
        }
    </div>
  )
}

export default NftCard