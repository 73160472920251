import { FC } from 'react'
import { CURRENCY_NAME } from '../../../../constants/staking'
import { Header, HeaderSize } from '../../../text/header/Header'
import { List, ListPointer } from '../../../text/list/List'
import classes from './styles.module.css'

const HowItWorks: FC = () => (
    <div className={classes.block}>
        <Header size={HeaderSize.Small} defaultLineHeight>How it works?</Header>
        <List pointer={ListPointer.Number}>
           Stake your Amo\n
           Earn {CURRENCY_NAME}.\n
           Spend {CURRENCY_NAME} on rewards
        </List>
    </div>
)

export default HowItWorks