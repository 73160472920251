import { BN, web3 } from '@project-serum/anchor'
import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import { AnchorWallet } from '@solana/wallet-adapter-react'
import { MASTER_UIDS_ACCOUNT as uids } from '../../constants/master'
import { userMasterProgram } from '../../utils/local/master'
import { userProvider } from '../../utils/solana'

const systemProgram = web3.SystemProgram.programId

type GetOrCreateUserResponse = {
    user: web3.PublicKey,
    instruction?: web3.TransactionInstruction
}

export const getOrCreateUser: (wallet: AnchorWallet) => Promise<GetOrCreateUserResponse> = async (wallet) => {
    const program = userMasterProgram(userProvider(wallet))

    const [user] = web3.PublicKey.findProgramAddressSync(
        [utf8.encode("user_v2"), wallet.publicKey.toBuffer()],
        program.programId
    )

    try {
        await program.account.userV2.fetch(user)

        return {
            user
        }
    } catch {
        const instruction = await program.methods.initializeUserV2().accounts({
            uids,
            user,
            signer: wallet.publicKey,
            systemProgram
        }).instruction()

        return {
            user,
            instruction
        }
    }
}

export const getUserBalance: (wallet: AnchorWallet) => Promise<number> = async (wallet) => {
    const program = userMasterProgram(userProvider(wallet))

    const [user] = web3.PublicKey.findProgramAddressSync(
        [utf8.encode("user_v2"), wallet.publicKey.toBuffer()],
        program.programId
    )

    try {
        const fetched = await program.account.userV2.fetch(user)
        const balances = fetched.balances as any[]
        const gold = balances.find(b => b.id === 0) as { amount: BN } | undefined
        return gold ? gold.amount.div(new BN(1_00_000)).toNumber() : 0
    } catch {
        return 0
    }

}