import { AnchorProvider, Program } from "@project-serum/anchor";
import { REWARD_RATES, REWARD_RATE_PERIOD, STAKING_PROGRAM_ID } from "../../constants/staking";
import { StakingProgram, STAKING_IDL } from "../../types/staking/program";
import { connection } from "../solana";

export const anonymousStakingProgram = new Program<StakingProgram>(STAKING_IDL, STAKING_PROGRAM_ID, { connection })
export const userStakingProgram = (provider: AnchorProvider) => new Program<StakingProgram>(STAKING_IDL, STAKING_PROGRAM_ID, provider)

export const getRewardForTimestamps: (stakeTimestamp: number, claimTimestamp: number, currentTime: number) => number = (stakeTimestamp, claimTimestamp, currentTime) => {

    let lockup_period = currentTime - stakeTimestamp
    let last_redeemed_time = currentTime

    let matching_rates = REWARD_RATES.filter(rate => Number(rate.days) * REWARD_RATE_PERIOD <= lockup_period).sort((a, b) => Number(b.days) - Number(a.days))

    let reward = 0

    for (let i = 0; i < matching_rates.length; i++) {
        let rate = matching_rates[i]
        let entry_time = Number(rate.days) * REWARD_RATE_PERIOD + stakeTimestamp
        if (entry_time >= claimTimestamp) {
            let time_passed = last_redeemed_time - entry_time
            reward += time_passed / REWARD_RATE_PERIOD * Number(rate.reward)
            last_redeemed_time = entry_time
        } else {
            let time_passed = last_redeemed_time - claimTimestamp
            reward += time_passed / REWARD_RATE_PERIOD * Number(rate.reward)
            break;
        }
    }

    return reward < 1 ? Math.floor(reward * 100) / 100 : reward < 10 ? Math.floor(reward * 10) / 10 : Math.floor(reward)
}

export const getCurrentRateForTimestamps: (stakeTimestamp: number, currentTimestamp: number) => number = (stakeTimestamp, currentTimestamp) => {

    let lockup_period = currentTimestamp - stakeTimestamp

    let matching_rate = REWARD_RATES.filter(rate => Number(rate.days) * REWARD_RATE_PERIOD <= lockup_period).sort((a, b) => Number(b.days) - Number(a.days))[0]

    return Number(matching_rate.reward)
}

export const getRewardProgressForTimestamp: (claimTimestamp: number, currentTime: number) => number = (claimTimestamp, currentTime) => {

    let passed_time = currentTime - claimTimestamp

    return Math.floor((passed_time - Math.floor(passed_time / REWARD_RATE_PERIOD) * REWARD_RATE_PERIOD) / REWARD_RATE_PERIOD * 100)

}