export const unixTimestamp = () => Math.floor(Date.now() / 1000)

type timeObject = {
    seconds: number,
    minutes: number,
    hours: number,
    days: number,
    months: number
}

export const unixTimestampToObject: (unixTimestamp: number) => timeObject = (unixTimestamp) => {
    const oneMonth = 60 * 60 * 24 * 30
    const oneDay = 60 * 60 * 24
    const oneHour = 60 * 60
    const oneMinute = 60
    let time: timeObject = {
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0,
        months: 0
    }
    if (unixTimestamp <= 0) return time

    time.months = Math.floor(unixTimestamp / oneMonth)
    unixTimestamp -= time.months * oneMonth

    time.days = Math.floor(unixTimestamp / oneDay)
    unixTimestamp -= time.days * oneDay

    time.hours = Math.floor(unixTimestamp / oneHour)
    unixTimestamp -= time.hours * oneHour

    time.minutes = Math.floor(unixTimestamp / oneMinute)
    unixTimestamp -= time.minutes * oneMinute

    time.seconds = unixTimestamp

    return time

}

export const classBuilder: (
    classes: { [key: string]: string }, 
    conditions: (string | { [key: string] : boolean | undefined } | undefined)[],
    rest?: string
) => string = (classes, conditions, rest) => {

    const matching = conditions.filter(condition => {
        if (typeof condition === 'object') return !!condition[Object.keys(condition)[0]]
        else return !!condition
    }) as (string | { [key: string] : boolean })[]
    
    const classNames = matching.map(condition => {
        if (typeof condition === "object") return Object.keys(condition)[0]
        else return condition
    })

    const result = Object.keys(classes).filter(className => classNames.includes(className)).map(className => classes[className])
    if (rest) result.push(rest)

    return result.join(' ')
}