import { AnchorWallet } from '@solana/wallet-adapter-react'
import { Dispatch, FC, SetStateAction, useCallback, useContext, useMemo } from 'react'
import { AppContext } from '../../../../AppContext'
import { CURRENCY_NAME, DEFAULT_LOCKUP_LENGTH } from '../../../../constants/staking'
import { useTransaction } from '../../../../hooks/useTransaction'
import { TimeContext } from '../../../../pages/staking/Staking'
import { claimReward, stakeNft, StakeResponse } from '../../../../requests/staking/programSimpleTransactions'
import { StakedNft, UnstakedNft } from '../../../../types/staking/nfts'
import Button, { ButtonColor, ButtonSize } from '../../button/Button'
import classes from './styles.module.css'

type StakingButtonsProps = {
  nfts: (UnstakedNft | StakedNft)[],
  setNfts: Dispatch<SetStateAction<(UnstakedNft | StakedNft)[]>>,
  claimableReward: number
}

const StakingButtons: FC<StakingButtonsProps> = ({nfts, setNfts, claimableReward}) => {

  const currentTimestamp = useContext(TimeContext)
  const { refreshBalance } = useContext(AppContext)

  const stakedNfts = useMemo<StakedNft[]>(
    () => nfts.filter(nft => nft.status === 'staked') as StakedNft[], [nfts]
  )

  const unstakedNfts = useMemo<UnstakedNft[]>(
    () => nfts.filter(nft => nft.status === 'unstaked') as UnstakedNft[], [nfts]
  )

  const stakeBuilder = useCallback(
    (wallet: AnchorWallet) => stakeNft(wallet, unstakedNfts), [unstakedNfts]
  )

  const stake = useTransaction(
    stakeBuilder,
    `Yay! Your ${unstakedNfts.length} Amos were successfully staked!`,
    undefined,
    () => unstakedNfts.length > 0,
    (response: StakeResponse[]) => setNfts(prev => {
      let upd = prev.map<StakedNft | UnstakedNft>(nft => {
        if (nft.status === 'unstaked') {
            let filtered = response.filter(s => s.mint.toBase58() === nft.mint.toBase58())
            if (!filtered || !filtered.length) return nft
            let stake = filtered[0].stake
            return {
              ...nft,
              stake,
              claimTimestamp: currentTimestamp,
              stakeTimestamp: currentTimestamp,
              unlockTimestamp: currentTimestamp + DEFAULT_LOCKUP_LENGTH,
              status: 'staked'
            }
        }
        else return nft
      })

      return upd
    })
  )

  const claimBuilder = useCallback(
    (wallet: AnchorWallet) => claimReward(wallet, stakedNfts), [stakedNfts]
  )

  const claim = useTransaction(
    claimBuilder,
    `Yay! ${claimableReward.toFixed(0)} Gold claimed! Rich boy!`,
    () => {
      setNfts(prev => {
        let upd = prev.map(nft => {
          if (nft.status === 'staked') return {
              ...nft,
              claimTimestamp: currentTimestamp
          }
          else return nft
        })
        return upd
      })
      if (refreshBalance) setTimeout(refreshBalance, 5000)
    },
    () => stakedNfts.length > 0
  )

  return (
    <div className={classes.buttons}>
        <Button unavailable={unstakedNfts.length === 0} onClick={stake} size={ButtonSize.Small} color={ButtonColor.Contrast}>Stake All Amo</Button>
        <Button unavailable={stakedNfts.length === 0} onClick={claim} size={ButtonSize.Small} >{`Claim all ${CURRENCY_NAME}`}</Button>
    </div>
  )
}

export default StakingButtons