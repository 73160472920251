import { FC } from 'react';
import { classBuilder } from '../../../utils/general';
import classes from './styles.module.css';

type HeaderProps = {
  size: HeaderSize,
  children:  string | JSX.Element | (string | JSX.Element)[],
  span?: boolean,
  defaultLineHeight?: boolean,
  contrast?: boolean,
  classes?: string
}

export enum HeaderSize {
  Vast = 'vast',
  ExtraLarge = 'extra_large',
  Large = 'large',
  MediumLarge = 'medium_large',
  Medium = 'medium',
  MediumSmall = 'medium_small',
  Small = 'small'
}

export const Header: FC<HeaderProps> = (props) => {

  const className = classBuilder(classes, [props.size, { contrast: props.contrast }, { default_line_height: props.defaultLineHeight }], props.classes)

  if (props.span) return <span className={className}>{props.children}</span>

  if (props.size === HeaderSize.Vast || props.size === HeaderSize.ExtraLarge || props.size === HeaderSize.Large) return <h1 className={className}>{props.children}</h1>
  if (props.size === HeaderSize.MediumLarge) return <h2 className={className}>{props.children}</h2>
  if (props.size === HeaderSize.Medium) return <h3 className={className}>{props.children}</h3>
  if (props.size === HeaderSize.MediumSmall) return <h4 className={className}>{props.children}</h4>
  if (props.size === HeaderSize.Small) return <h5 className={className}>{props.children}</h5>

  return null

}