import { FC } from 'react'
import { CURRENCY_NAME } from '../../../../constants/staking'
import { Header, HeaderSize } from '../../../text/header/Header'
import { List, ListPointer } from '../../../text/list/List'
import classes from './styles.module.css'

const RewardUsage: FC = () => (
    <div className={classes.block}>
        <Header size={HeaderSize.Small} defaultLineHeight>Accumulate {CURRENCY_NAME} and use it for:</Header>
        <List pointer={ListPointer.Dot}>
            IRL events\n
            Amo wear\n
            Raffles\n
            Giveaways\n
            Customization of your Amo\n
            And much more on our platform...
        </List>
    </div>
)

export default RewardUsage