import { MutableRefObject, useEffect } from "react"

export const useOutsideClick = (ref: MutableRefObject<any>, callback: () => void, allowedRefs?: MutableRefObject<any>[]) => {

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (!ref.current || ref.current.contains(e.target) || allowedRefs?.some(r => r.current.contains(e.target))) return
            callback()
        }

        document.addEventListener('mousedown', handleClick)
        return () => document.removeEventListener('mousedown', handleClick)
    // eslint-disable-next-line
    }, [ref])

}