export type StakingProgram = {
  "version": "0.2.3",
  "name": "staking",
  "instructions": [
    {
      "name": "initializePoolIds",
      "accounts": [
        {
          "name": "poolIds",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "id",
          "type": "u8"
        }
      ]
    },
    {
      "name": "initializePoolV2",
      "accounts": [
        {
          "name": "pool",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolIds",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authorities",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "InitializePoolV2Data"
          }
        }
      ]
    },
    {
      "name": "managePoolV2",
      "accounts": [
        {
          "name": "pool",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authorities",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "ManagePoolV2Data"
          }
        }
      ]
    },
    {
      "name": "reallocPoolV2",
      "accounts": [
        {
          "name": "pool",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authorities",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "InitializePoolV2Data"
          }
        }
      ]
    },
    {
      "name": "stakeItemV2",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemEdition",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenMetadataProgram",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "StakeV2Data"
          }
        }
      ]
    },
    {
      "name": "stakeItemV2Ocp",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMintState",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "policy",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "freezeAuthority",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "ocpProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "cmpProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "instructions",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "StakeV2Data"
          }
        }
      ]
    },
    {
      "name": "stakeItemV2Pnft",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemEdition",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenRecord",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "tokenAuthRules",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenAuthRulesProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenMetadataProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "instructions",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "StakeV2Data"
          }
        }
      ]
    },
    {
      "name": "claimRewardV2",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "unstakeItemV2",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemEdition",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenMetadataProgram",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "masterProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "unstakeItemV2Ocp",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMintState",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "policy",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "freezeAuthority",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "ocpProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "cmpProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "instructions",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "masterProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "unstakeItemV2Pnft",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemEdition",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenRecord",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "tokenAuthRules",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenAuthRulesProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenMetadataProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "instructions",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "masterProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "poolIds",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u8"
          },
          {
            "name": "ids",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "poolV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u16"
          },
          {
            "name": "includeFees",
            "type": "bool"
          },
          {
            "name": "authorities",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "itemType",
            "type": "u8"
          },
          {
            "name": "creators",
            "type": {
              "vec": {
                "defined": "CollectionCreatorV2"
              }
            }
          },
          {
            "name": "verifiedCollection",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "lockups",
            "type": {
              "vec": "i64"
            }
          },
          {
            "name": "currencyId",
            "type": "u16"
          },
          {
            "name": "rewardScheme",
            "type": "u8"
          },
          {
            "name": "rewards",
            "type": {
              "vec": {
                "defined": "RewardV2"
              }
            }
          },
          {
            "name": "allowList",
            "type": {
              "option": {
                "defined": "AllowList"
              }
            }
          }
        ]
      }
    },
    {
      "name": "stakeV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": {
              "array": [
                "u8",
                1
              ]
            }
          },
          {
            "name": "poolId",
            "type": "u16"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "timestamp",
            "type": "i64"
          },
          {
            "name": "lockup",
            "type": "i64"
          },
          {
            "name": "claimTimestamp",
            "type": "i64"
          }
        ]
      }
    },
    {
      "name": "stakeBuffer",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "stakeBump",
            "type": "u8"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "timestamp",
            "type": "i64"
          },
          {
            "name": "lockup",
            "type": "i64"
          },
          {
            "name": "claimTimestamp",
            "type": "i64"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "CollectionCreatorV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "verified",
            "type": "bool"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "RewardV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "minLockup",
            "type": "i64"
          },
          {
            "name": "currencyPerDay",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "AllowList",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "merkleRoot",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "InitializePoolV2Data",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authoritiesLen",
            "type": "u8"
          },
          {
            "name": "creatorsLen",
            "type": "u8"
          },
          {
            "name": "lockupsLen",
            "type": "u8"
          },
          {
            "name": "rewardsLen",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2AuthoritiesData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2CreatorsData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "verified",
            "type": "bool"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2UpdateData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "itemType",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "updateVerifiedCollection",
            "type": "bool"
          },
          {
            "name": "verifiedCollection",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "currencyId",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "rewardScheme",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "updateAllowList",
            "type": "bool"
          },
          {
            "name": "allowList",
            "type": {
              "option": {
                "defined": "AllowList"
              }
            }
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2LockupsData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "lockup",
            "type": "i64"
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2RewardsData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "minLockup",
            "type": "i64"
          },
          {
            "name": "currencyPerDay",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "StakeV2Data",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "lockup",
            "type": "i64"
          },
          {
            "name": "merkleProof",
            "type": {
              "option": "bytes"
            }
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2Data",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "ManageAuthorities",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2AuthoritiesData"
                }
              }
            ]
          },
          {
            "name": "ManageCreators",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2CreatorsData"
                }
              }
            ]
          },
          {
            "name": "Update",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2UpdateData"
                }
              }
            ]
          },
          {
            "name": "ManageLockups",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2LockupsData"
                }
              }
            ]
          },
          {
            "name": "ManageRewards",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2RewardsData"
                }
              }
            ]
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "MetadataUninitialized",
      "msg": "Metadata account is not initialized!"
    },
    {
      "code": 6001,
      "name": "NoCollection",
      "msg": "Item has no collection"
    },
    {
      "code": 6002,
      "name": "NoCreators",
      "msg": "Item has no creators"
    },
    {
      "code": 6003,
      "name": "CollectionUnverified",
      "msg": "Item collection is not verified"
    },
    {
      "code": 6004,
      "name": "CollectionMismatch",
      "msg": "Item belongs to invalid collection"
    },
    {
      "code": 6005,
      "name": "CreatorMismatch",
      "msg": "Item has invalid verified creator"
    },
    {
      "code": 6006,
      "name": "LockupNotEnded",
      "msg": "Lockup period has not ended yet"
    },
    {
      "code": 6007,
      "name": "NotAllowed",
      "msg": "Transaction signer could not perform this action"
    },
    {
      "code": 6008,
      "name": "InvalidUserProvided",
      "msg": "Invalid user instance provided"
    },
    {
      "code": 6009,
      "name": "LockupDuplicate",
      "msg": "Lockup already exists"
    },
    {
      "code": 6010,
      "name": "RewardDuplicate",
      "msg": "Reward already exists"
    },
    {
      "code": 6011,
      "name": "InvalidLockup",
      "msg": "Provided lockup does not exist"
    },
    {
      "code": 6012,
      "name": "CreatorDuplicate",
      "msg": "Creator already exists"
    },
    {
      "code": 6013,
      "name": "AuthorityDuplicate",
      "msg": "Authority already exists"
    },
    {
      "code": 6014,
      "name": "InvalidSpace",
      "msg": "Invalid space provided for reallocation"
    },
    {
      "code": 6015,
      "name": "InvalidPool",
      "msg": "Invalid pool account provided for the instruction"
    },
    {
      "code": 6016,
      "name": "InvalidFeeReceiver",
      "msg": "Invalid fee receiver provided for the instruction"
    },
    {
      "code": 6017,
      "name": "BumpMismatch",
      "msg": "Provided bump does not match expected"
    },
    {
      "code": 6018,
      "name": "InvalidTokenType",
      "msg": "Invalid token type for this instruction"
    },
    {
      "code": 6019,
      "name": "InvalidData",
      "msg": "Invalid data provided for this instruction"
    },
    {
      "code": 6020,
      "name": "ItemNotFoundInAllowlist",
      "msg": "Item not found in the allowlist"
    }
  ]
};

export const STAKING_IDL: StakingProgram = {
  "version": "0.2.3",
  "name": "staking",
  "instructions": [
    {
      "name": "initializePoolIds",
      "accounts": [
        {
          "name": "poolIds",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "id",
          "type": "u8"
        }
      ]
    },
    {
      "name": "initializePoolV2",
      "accounts": [
        {
          "name": "pool",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "poolIds",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authorities",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "InitializePoolV2Data"
          }
        }
      ]
    },
    {
      "name": "managePoolV2",
      "accounts": [
        {
          "name": "pool",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authorities",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "ManagePoolV2Data"
          }
        }
      ]
    },
    {
      "name": "reallocPoolV2",
      "accounts": [
        {
          "name": "pool",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "authorities",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "InitializePoolV2Data"
          }
        }
      ]
    },
    {
      "name": "stakeItemV2",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemEdition",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenMetadataProgram",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "StakeV2Data"
          }
        }
      ]
    },
    {
      "name": "stakeItemV2Ocp",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMintState",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "policy",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "freezeAuthority",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "ocpProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "cmpProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "instructions",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "StakeV2Data"
          }
        }
      ]
    },
    {
      "name": "stakeItemV2Pnft",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemEdition",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenRecord",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "tokenAuthRules",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenAuthRulesProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenMetadataProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "instructions",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "StakeV2Data"
          }
        }
      ]
    },
    {
      "name": "claimRewardV2",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "masterProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "unstakeItemV2",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemEdition",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenMetadataProgram",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "masterProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "unstakeItemV2Ocp",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMintState",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "policy",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "freezeAuthority",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "ocpProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "cmpProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "instructions",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "masterProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "unstakeItemV2Pnft",
      "accounts": [
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stake",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "pool",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "cpiSigner",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeReceiver",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "item",
          "accounts": [
            {
              "name": "signer",
              "isMut": true,
              "isSigner": true
            },
            {
              "name": "itemAccount",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemMetadata",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "itemEdition",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "itemMint",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenRecord",
              "isMut": true,
              "isSigner": false
            },
            {
              "name": "tokenAuthRules",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenAuthRulesProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "tokenMetadataProgram",
              "isMut": false,
              "isSigner": false
            },
            {
              "name": "instructions",
              "isMut": false,
              "isSigner": false
            }
          ]
        },
        {
          "name": "masterProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "poolIds",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u8"
          },
          {
            "name": "ids",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "poolV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u16"
          },
          {
            "name": "includeFees",
            "type": "bool"
          },
          {
            "name": "authorities",
            "type": {
              "vec": "publicKey"
            }
          },
          {
            "name": "itemType",
            "type": "u8"
          },
          {
            "name": "creators",
            "type": {
              "vec": {
                "defined": "CollectionCreatorV2"
              }
            }
          },
          {
            "name": "verifiedCollection",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "lockups",
            "type": {
              "vec": "i64"
            }
          },
          {
            "name": "currencyId",
            "type": "u16"
          },
          {
            "name": "rewardScheme",
            "type": "u8"
          },
          {
            "name": "rewards",
            "type": {
              "vec": {
                "defined": "RewardV2"
              }
            }
          },
          {
            "name": "allowList",
            "type": {
              "option": {
                "defined": "AllowList"
              }
            }
          }
        ]
      }
    },
    {
      "name": "stakeV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": {
              "array": [
                "u8",
                1
              ]
            }
          },
          {
            "name": "poolId",
            "type": "u16"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "timestamp",
            "type": "i64"
          },
          {
            "name": "lockup",
            "type": "i64"
          },
          {
            "name": "claimTimestamp",
            "type": "i64"
          }
        ]
      }
    },
    {
      "name": "stakeBuffer",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "stakeBump",
            "type": "u8"
          },
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "mint",
            "type": "publicKey"
          },
          {
            "name": "timestamp",
            "type": "i64"
          },
          {
            "name": "lockup",
            "type": "i64"
          },
          {
            "name": "claimTimestamp",
            "type": "i64"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "CollectionCreatorV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "verified",
            "type": "bool"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "RewardV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "minLockup",
            "type": "i64"
          },
          {
            "name": "currencyPerDay",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "AllowList",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "merkleRoot",
            "type": {
              "array": [
                "u8",
                32
              ]
            }
          }
        ]
      }
    },
    {
      "name": "InitializePoolV2Data",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "authoritiesLen",
            "type": "u8"
          },
          {
            "name": "creatorsLen",
            "type": "u8"
          },
          {
            "name": "lockupsLen",
            "type": "u8"
          },
          {
            "name": "rewardsLen",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2AuthoritiesData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2CreatorsData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "verified",
            "type": "bool"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2UpdateData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "itemType",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "updateVerifiedCollection",
            "type": "bool"
          },
          {
            "name": "verifiedCollection",
            "type": {
              "option": "publicKey"
            }
          },
          {
            "name": "currencyId",
            "type": {
              "option": "u16"
            }
          },
          {
            "name": "rewardScheme",
            "type": {
              "option": "u8"
            }
          },
          {
            "name": "updateAllowList",
            "type": "bool"
          },
          {
            "name": "allowList",
            "type": {
              "option": {
                "defined": "AllowList"
              }
            }
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2LockupsData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "lockup",
            "type": "i64"
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2RewardsData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "minLockup",
            "type": "i64"
          },
          {
            "name": "currencyPerDay",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "StakeV2Data",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "lockup",
            "type": "i64"
          },
          {
            "name": "merkleProof",
            "type": {
              "option": "bytes"
            }
          }
        ]
      }
    },
    {
      "name": "ManagePoolV2Data",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "ManageAuthorities",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2AuthoritiesData"
                }
              }
            ]
          },
          {
            "name": "ManageCreators",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2CreatorsData"
                }
              }
            ]
          },
          {
            "name": "Update",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2UpdateData"
                }
              }
            ]
          },
          {
            "name": "ManageLockups",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2LockupsData"
                }
              }
            ]
          },
          {
            "name": "ManageRewards",
            "fields": [
              {
                "name": "data",
                "type": {
                  "defined": "ManagePoolV2RewardsData"
                }
              }
            ]
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "MetadataUninitialized",
      "msg": "Metadata account is not initialized!"
    },
    {
      "code": 6001,
      "name": "NoCollection",
      "msg": "Item has no collection"
    },
    {
      "code": 6002,
      "name": "NoCreators",
      "msg": "Item has no creators"
    },
    {
      "code": 6003,
      "name": "CollectionUnverified",
      "msg": "Item collection is not verified"
    },
    {
      "code": 6004,
      "name": "CollectionMismatch",
      "msg": "Item belongs to invalid collection"
    },
    {
      "code": 6005,
      "name": "CreatorMismatch",
      "msg": "Item has invalid verified creator"
    },
    {
      "code": 6006,
      "name": "LockupNotEnded",
      "msg": "Lockup period has not ended yet"
    },
    {
      "code": 6007,
      "name": "NotAllowed",
      "msg": "Transaction signer could not perform this action"
    },
    {
      "code": 6008,
      "name": "InvalidUserProvided",
      "msg": "Invalid user instance provided"
    },
    {
      "code": 6009,
      "name": "LockupDuplicate",
      "msg": "Lockup already exists"
    },
    {
      "code": 6010,
      "name": "RewardDuplicate",
      "msg": "Reward already exists"
    },
    {
      "code": 6011,
      "name": "InvalidLockup",
      "msg": "Provided lockup does not exist"
    },
    {
      "code": 6012,
      "name": "CreatorDuplicate",
      "msg": "Creator already exists"
    },
    {
      "code": 6013,
      "name": "AuthorityDuplicate",
      "msg": "Authority already exists"
    },
    {
      "code": 6014,
      "name": "InvalidSpace",
      "msg": "Invalid space provided for reallocation"
    },
    {
      "code": 6015,
      "name": "InvalidPool",
      "msg": "Invalid pool account provided for the instruction"
    },
    {
      "code": 6016,
      "name": "InvalidFeeReceiver",
      "msg": "Invalid fee receiver provided for the instruction"
    },
    {
      "code": 6017,
      "name": "BumpMismatch",
      "msg": "Provided bump does not match expected"
    },
    {
      "code": 6018,
      "name": "InvalidTokenType",
      "msg": "Invalid token type for this instruction"
    },
    {
      "code": 6019,
      "name": "InvalidData",
      "msg": "Invalid data provided for this instruction"
    },
    {
      "code": 6020,
      "name": "ItemNotFoundInAllowlist",
      "msg": "Item not found in the allowlist"
    }
  ]
};
