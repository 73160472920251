import { useState, useCallback } from "react"

export enum Theme {
    Light = 'light',
    Dark = 'dark'
}

const getTheme = (key: string) => {
    const theme = localStorage.getItem(key)
    if (theme) executeTheme(theme as Theme)
    return theme
}

const initializeTheme = (key: string) => {
    let theme = (window.matchMedia('(prefers-color-scheme: dark)').matches) ? Theme.Dark : Theme.Light
    setTheme(key, theme)
    return theme
}

const setTheme = (key: string, value: Theme) => {
    localStorage.setItem(key, value)
    executeTheme(value)
    return value
}

const executeTheme = (value: Theme) => {
    if (value === Theme.Light) document.body.classList.remove(Theme.Dark)
    else document.body.classList.add(Theme.Dark)
}

export const useTheme = () => {

    const key: string = 'theme'

    const [state, setState] = useState(
        () => getTheme(key) || initializeTheme(key)
    )

    const setStoredTheme = useCallback(
        (newValue: Theme) => {
            setState(newValue)
            executeTheme(newValue)
            return setTheme(key, newValue)
        },
        [key, setState]
    )

    return [state, setStoredTheme] as const
}