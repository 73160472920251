import { FC, useState } from 'react'
import { Header, HeaderSize } from '../../components/text/header/Header'
import { Paragraph, ParagraphSize, ParagraphWeight } from '../../components/text/paragraph/Paragraph'
import { useMobileDevice } from '../../hooks/useDevice'
import classes from './styles.module.css'

const Home: FC = () => {

  const isMobile = useMobileDevice()
  const [hideVideo, setHideVideo] = useState<boolean>(true)

  const onPlay = () => {
    setHideVideo(false)
  }

  return (
    <>
    <section className={classes.background}>
      <div className={classes.content}>
        <Header size={HeaderSize.Vast} contrast>Amo Deos</Header>
        <Paragraph size={ParagraphSize.MediumLarge} weight={ParagraphWeight.Thin} contrast>AmoDeos is a virtual home for web3 builders, investors and researchers.</Paragraph>
        <div className={classes.icons}>
          <div onClick={() => window.open('https://discord.gg/3nNXUH43Pa', '_blank')}>
            <svg viewBox="0 0 24 17" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.303 2.59228C19.9786 2.1022 19.5192 1.72732 18.9744 1.5094C17.7917 1.03516 16.7746 0.718364 15.8645 0.539804C15.2261 0.415004 14.5939 0.728924 14.2901 1.32028L14.2142 1.46812C13.5403 1.39372 12.8246 1.36876 12.0096 1.3894C11.1739 1.36924 10.4558 1.39372 9.78096 1.46812L9.7056 1.32028C9.40176 0.728924 8.76864 0.415484 8.13168 0.540284C7.2216 0.718364 6.204 1.03516 5.02176 1.50988C4.47744 1.7278 4.01808 2.1022 3.69312 2.59276C1.42704 6.01564 0.53904 9.7078 0.97872 13.8809C0.99408 14.0273 1.07568 14.1584 1.2 14.2366C2.94336 15.3358 4.452 16.0884 5.94768 16.6059C6.57312 16.8243 7.26672 16.58 7.63584 16.016L8.29392 15.0075C7.76976 14.8097 7.25808 14.5755 6.76752 14.2956C6.5376 14.1646 6.45744 13.8713 6.58848 13.6414C6.71952 13.4105 7.0128 13.3294 7.2432 13.4624C8.70288 14.2947 10.3531 14.7348 12.0154 14.7348C13.6776 14.7348 15.3278 14.2947 16.7875 13.4624C17.0174 13.3294 17.3107 13.4105 17.4422 13.6414C17.5733 13.8713 17.4931 14.1646 17.2632 14.2956C16.7563 14.5851 16.2264 14.826 15.6835 15.0272L16.3608 16.0361C16.6397 16.4513 17.1 16.6875 17.5742 16.6875C17.7288 16.6875 17.8848 16.662 18.0365 16.6102C19.5365 16.0923 21.048 15.3387 22.7952 14.2371C22.9195 14.1588 23.0011 14.0273 23.0165 13.8814C23.4571 9.7078 22.5691 6.01516 20.303 2.59228ZM8.93184 10.9121C8.00736 10.9121 7.24656 9.9382 7.24656 8.75453C7.24656 7.57085 8.00736 6.59692 8.93184 6.59692C9.85632 6.59692 10.6171 7.57085 10.6171 8.75453C10.6171 9.9382 9.85632 10.9121 8.93184 10.9121ZM15.1685 10.8996C14.2526 10.8996 13.499 9.91996 13.499 8.72908C13.499 7.5382 14.2526 6.55852 15.1685 6.55852C16.0843 6.55852 16.8379 7.5382 16.8379 8.72908C16.8379 9.91996 16.0843 10.8996 15.1685 10.8996Z"/>
            </svg>
          </div>
          <div onClick={() => window.open('https://twitter.com/AmoDeosNft', '_blank')}>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4569 0H4.5431C2.03848 0 0 2.03848 0 4.5468V15.4532C0 17.9615 2.03848 20 4.5431 20H15.4569C17.9615 20 20 17.9615 20 15.4532V4.5431C20 2.03848 17.9615 0 15.4569 0ZM15.1091 7.94673C15.1128 8.05401 15.1165 8.1613 15.1165 8.27229C15.1165 11.6093 12.5823 15.4569 7.95043 15.4569C6.52978 15.4569 5.20533 15.0388 4.09175 14.3211C4.28783 14.3433 4.48761 14.3544 4.69108 14.3544C5.87125 14.3544 6.95523 13.9512 7.81724 13.2741C6.71476 13.2556 5.78616 12.5231 5.4643 11.5205C5.61968 11.5501 5.77877 11.5686 5.94155 11.5686C6.17092 11.5686 6.3929 11.5353 6.60377 11.4798C5.4532 11.2468 4.5838 10.2257 4.5838 9.00111C4.5838 8.99001 4.5838 8.98261 4.5838 8.97151C4.92416 9.16019 5.31262 9.27488 5.72327 9.28598C5.04624 8.83463 4.60599 8.06141 4.60599 7.18461C4.60599 6.72216 4.72808 6.28931 4.94636 5.91565C6.18572 7.44358 8.04292 8.44987 10.1369 8.55346C10.0925 8.36848 10.0703 8.1761 10.0703 7.98002C10.0703 6.58528 11.1987 5.4532 12.5897 5.4532C13.3111 5.4532 13.966 5.76027 14.4247 6.25231C14.9981 6.13762 15.5383 5.92675 16.0266 5.63818C15.838 6.22642 15.4384 6.72216 14.9168 7.03663C15.4273 6.97373 15.912 6.83685 16.3633 6.63707C16.0266 7.14391 15.6012 7.58787 15.1091 7.94673Z"/>
            </svg>
          </div>
        </div>
      </div>
      <video onPlay={onPlay} className={hideVideo ? classes.hidden : classes.video} src={isMobile ? 'media/mobile_fire.mp4' : 'media/desktop_fire.mp4'} autoPlay loop muted controls={false} playsInline/>
      </section>
    <img className={classes.image} src='./media/amo_character.png' alt='Amo'/>
    </>
  )
}

export default Home