import { useWallet } from '@solana/wallet-adapter-react'
import { FC, PropsWithChildren } from 'react'
import { Route, Routes } from 'react-router-dom'
import Navbar from './components/static/navbar/Navbar'
import { useMobileDevice } from './hooks/useDevice'
import Community from './pages/community/Community'
import Home from './pages/home/Home'
import Manifesto from './pages/manifesto/Manifesto'
import Staking from './pages/staking/Staking'
import StakingLanding from './pages/staking_landing.tsx/StakingLanding'

type PageWrapperProps = {
    backgroundColorNavbar?: boolean,
    smallTopPadding?: boolean,
    disableWalletButton?: boolean
}

const PageWrapper: FC<PropsWithChildren<PageWrapperProps>> = ({children, backgroundColorNavbar, smallTopPadding, disableWalletButton}) => {
    return (
      <section className='page'>
              <Navbar backgroundColor={backgroundColorNavbar} disableWalletButton={disableWalletButton}/>
          <section className={'content' + (smallTopPadding ? ' small_top_padding' : '')}>
              {children}
          </section>
      </section>
    )
}

const AppRouter: FC = () => {

    const { publicKey } = useWallet()
    const isMobile = useMobileDevice()

    return (
        <Routes>
            <Route path='/' element={<PageWrapper smallTopPadding backgroundColorNavbar disableWalletButton={isMobile}><Home/></PageWrapper>}/>
            <Route path='/staking' element={publicKey ? <PageWrapper><Staking/></PageWrapper> : <PageWrapper><StakingLanding/></PageWrapper>}/>
            <Route path='/community' element={<PageWrapper disableWalletButton={isMobile}><Community/></PageWrapper>}/>
            <Route path='/manifesto' element={<PageWrapper backgroundColorNavbar smallTopPadding disableWalletButton={isMobile}><Manifesto/></PageWrapper>}/>
        </Routes>
    )
}

export default AppRouter