import { web3 } from '@project-serum/anchor'

export const CURRENCY_NAME = 'Gold'

type RewardRate = {
    days: string,
    reward: string
}

export const REWARD_RATES: RewardRate[] = [
    {
        days: '0',
        reward: '10'
    },
    {
        days: '7',
        reward: '11'
    },
    {
        days: '14',
        reward: '12'
    },
    {
        days: '30',
        reward: '16'
    }
]

export const STAKING_PROGRAM_ID = new web3.PublicKey("Amoptmj4HoQJxBPAGDmwxZ9M6afgsUQmv1cvU5AGvBru")

export const ITEM_VERIFIED_CREATOR = new web3.PublicKey('HcWBYGG5Li4PyAKprpETEagPY1SRYeJCLGLZex83EZcw')
export const ITEM_VERIFIED_COLLECTION = new web3.PublicKey('HdjzDrBz3Q3CCuHRAeWQhFcNz2LcD1xERTgQZPbxqRN3')

export const STAKING_FEE_RECEIVER = new web3.PublicKey('8GYat9R9T6PGGUQ6uwMn3rSMpbuXSoDX3AHcQBnyiJyv')
export const STAKING_POOL_ACCOUNT = new web3.PublicKey('3MbXYDh3Kti9G6DWHDxokSTVw7qC76JdYdRUU11XJUB3')
export const STAKING_CPI_SIGNER = new web3.PublicKey('2BchNKRtegL8z742Ci3e4K41TCHSkyt8g76645mC1a2F')

export const DEFAULT_LOCKUP_LENGTH = 24 * 60 * 60
export const REWARD_RATE_PERIOD = 24 * 60 * 60