import { FC } from 'react'
import { classBuilder } from '../../../utils/general'
import classes from './styles.module.css'

type ButtonProps = {
    size: ButtonSize,
    color?: ButtonColor,
    disabled?: boolean,
    unavailable?: boolean,
    autoWidth?: boolean,
    onClick?: () => void,
    children: string
}

export enum ButtonSize {
  Medium = 'medium',
  Small = 'small'
}

export enum ButtonColor {
  Default = 'default',
  Contrast = 'contrast'
}

const Button: FC<ButtonProps> = ({ size, color, disabled, onClick, children, unavailable, autoWidth }) => {

  return (
    <button className={
      classBuilder(classes, [(color || ButtonColor.Default), size, { disabled }, { unavailable }, { auto_width: autoWidth }])
    } disabled={disabled || unavailable || false} onClick={onClick}>
      {
        disabled ?
        <svg xmlns="http://www.w3.org/2000/svg">
          <path d="M9.94477 5.05588H9.24636V3.65906C9.24636 1.73144 7.68191 0.166992 5.75429 0.166992C3.82667 0.166992 2.26223 1.73144 2.26223 3.65906V5.05588H1.56382C0.792072 5.05588 0.166992 5.68096 0.166992 6.45271V13.4368C0.166992 14.2086 0.792072 14.8337 1.56382 14.8337H9.94477C10.7165 14.8337 11.3416 14.2086 11.3416 13.4368V6.45271C11.3416 5.68096 10.7165 5.05588 9.94477 5.05588ZM5.75429 11.3416C4.98255 11.3416 4.35747 10.7165 4.35747 9.94477C4.35747 9.17302 4.98255 8.54794 5.75429 8.54794C6.52604 8.54794 7.15112 9.17302 7.15112 9.94477C7.15112 10.7165 6.52604 11.3416 5.75429 11.3416ZM7.91937 5.05588H3.58921V3.65906C3.58921 2.46477 4.56001 1.49398 5.75429 1.49398C6.94858 1.49398 7.91937 2.46477 7.91937 3.65906V5.05588Z"/>
        </svg>
        : null
      }
      {children}
    </button>
  )
}

export default Button