import { useState, useEffect } from "react"

export const useRefreshableEffect = (callback: (() => Promise<void>) | (() => void), dependencies: any[]) => {

    const [isRefresh, setIsRefresh] = useState<boolean>(true)

    useEffect(() => {
        callback()
    // eslint-disable-next-line
    }, [isRefresh, ...dependencies])
    

    const refresh = () => setIsRefresh(prev => !prev)

    return refresh

}