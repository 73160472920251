import { Dispatch, FC, SetStateAction } from "react"
import { NftFilter } from "../../../../types/staking/nfts"
import classes from './styles.module.css'

type StakingFilterProps = {
    current: NftFilter,
    filters: NftFilter[],
    setFilter: Dispatch<SetStateAction<NftFilter>>
}

const StakingFilter: FC<StakingFilterProps> = ({ current, filters, setFilter }) => {

  return (
    <div className={classes.wrapper}>
        {
            filters.map((f, i) => <button onClick={() => setFilter(f)} key={i} className={classes.tab + ' ' + (f.status === current.status ? classes.current : '')}>{f.label}</button>)
        }
    </div>
  )
}

export default StakingFilter