import { useEffect, useState } from "react"

export const useMobileDevice = () => {
    
    const width = useDevice()

    return width <= 800

}

export const useDevice = () => {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return width
}