import { useState } from "react"

export const usePrefetching = (callback: () => Promise<void>) => {

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<any>(null)

    const fetch = async () => {
        try {
            setIsLoading(true)
            await callback()
        } catch(e: any) {
            setError(e)
        } finally {
            setIsLoading(false)
        }
    }

    return [fetch, isLoading, error] as const
}