import { useWallet } from '@solana/wallet-adapter-react';
import { FC, useRef, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { useMobileDevice } from '../../../hooks/useDevice';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import WalletButton, { WalletButtonSize } from '../../dynamic/wallet_button/WalletButton';
import ThemeSwitcher from '../theme_switcher/ThemeSwitcher';
import classes from './styles.module.css';

type NavbarProps = {
    backgroundColor?: boolean,
    disableWalletButton?: boolean,
}

const Navbar: FC<NavbarProps> = ({backgroundColor, disableWalletButton}) => {

    const isMobile = useMobileDevice()
    const { publicKey } = useWallet()
    const [popup, setPopup] = useState<boolean>(false)

    const showPopup = () => setPopup(prev => !prev)
    
    const popupRef = useRef(null)
    const popupButtonRef = useRef(null)

    useOutsideClick(popupRef, showPopup, [popupButtonRef])

    if (!isMobile) return (
        <header className={classes.wrapper + ' ' + (backgroundColor ? classes.background_color : '')}>
            <section className={classes.navbar}>
                <section>
                    <Link to='../'>
                        <p className={classes.logo}>Amo.</p>
                    </Link>
                    <div className={classes.links}>
                        <CurrentLink to='../manifesto'>Manifesto</CurrentLink>
                        <CurrentLink to='../community'>Community</CurrentLink>
                        <CurrentLink to='../staking'>Staking</CurrentLink>
                    </div>  
                </section>
                <section>
                    <ThemeSwitcher/>
                    {
                        disableWalletButton ?
                        null :
                        <WalletButton size={WalletButtonSize.Inline}/>
                    }
                </section>
            </section>
        </header>
    )
    else return (
        <header className={classes.wrapper + ' ' + (backgroundColor ? classes.background_color : '')}>
            <section className={classes.navbar}>
                <section>
                    <Link to='../'>
                        <p className={classes.logo}>Amo.</p>
                    </Link>
                </section>
                <section className={classes.mobile}>
                    {
                        (publicKey && !disableWalletButton) ?
                        <WalletButton size={WalletButtonSize.Inline}/>
                        : ''
                    }
                    <div className={classes.burger} onClick={showPopup} ref={popupButtonRef}>
                        <svg viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {
                                popup 
                                ?
                                <path d="M1 1L17 17M1 17L17 1" strokeWidth="2" strokeLinecap="round"/> 
                                :
                                <path d="M20.5 16C21.052 16 21.5 16.4475 21.5 17C21.5 17.5525 21.052 18 20.5 18C20.1995 18 1.8005 18 1.5 18C0.948 18 0.5 17.5525 0.5 17C0.5 16.4475 0.948 16 1.5 16C1.8005 16 20.1995 16 20.5 16ZM20.5 8C21.052 8 21.5 8.4475 21.5 9C21.5 9.5525 21.052 10 20.5 10C20.1995 10 1.8005 10 1.5 10C0.948 10 0.5 9.5525 0.5 9C0.5 8.4475 0.948 8 1.5 8C1.8005 8 20.1995 8 20.5 8ZM20.5 0C21.052 0 21.5 0.4475 21.5 1C21.5 1.5525 21.052 2 20.5 2C20.1995 2 1.8005 2 1.5 2C0.948 2 0.5 1.5525 0.5 1C0.5 0.4475 0.948 0 1.5 0C1.8005 0 20.1995 0 20.5 0Z"/>
                            }
                        </svg>
                    </div>
                </section>
            </section>
            {
                popup ?
                <div ref={popupRef} className={classes.popup + ' ' + (backgroundColor ? classes.background_color : '')}>
                    <ul>
                        <Link onClick={showPopup} className={classes.link} to='../manifesto'>Manifesto</Link>
                        <Link onClick={showPopup} className={classes.link} to='../community'>Community</Link>
                        <Link onClick={showPopup} className={classes.link} to='../staking'>Staking</Link>
                    </ul>
                    <div>
                        <ThemeSwitcher/>
                        <div>
                            <svg onClick={() => window.open('https://discord.gg/3nNXUH43Pa', '_blank')} viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.3029 2.59228C19.9784 2.1022 19.5191 1.72732 18.9743 1.5094C17.7916 1.03516 16.7744 0.718364 15.8644 0.539804C15.226 0.415004 14.5938 0.728924 14.29 1.32028L14.2141 1.46812C13.5402 1.39372 12.8245 1.36876 12.0095 1.3894C11.1738 1.36924 10.4557 1.39372 9.78085 1.46812L9.70549 1.32028C9.40165 0.728924 8.76853 0.415484 8.13157 0.540284C7.22149 0.718364 6.20389 1.03516 5.02165 1.50988C4.47733 1.7278 4.01797 2.1022 3.69301 2.59276C1.42693 6.01564 0.538926 9.7078 0.978606 13.8809C0.993966 14.0273 1.07557 14.1584 1.19989 14.2366C2.94325 15.3358 4.45189 16.0884 5.94757 16.6059C6.57301 16.8243 7.26661 16.58 7.63573 16.016L8.29381 15.0075C7.76965 14.8097 7.25797 14.5755 6.76741 14.2956C6.53749 14.1646 6.45733 13.8713 6.58837 13.6414C6.71941 13.4105 7.01269 13.3294 7.24309 13.4624C8.70277 14.2947 10.353 14.7348 12.0152 14.7348C13.6775 14.7348 15.3277 14.2947 16.7874 13.4624C17.0173 13.3294 17.3106 13.4105 17.4421 13.6414C17.5732 13.8713 17.493 14.1646 17.2631 14.2956C16.7562 14.5851 16.2263 14.826 15.6834 15.0272L16.3607 16.0361C16.6396 16.4513 17.0999 16.6875 17.5741 16.6875C17.7287 16.6875 17.8847 16.662 18.0364 16.6102C19.5364 16.0923 21.0479 15.3387 22.7951 14.2371C22.9194 14.1588 23.001 14.0273 23.0164 13.8814C23.457 9.7078 22.569 6.01516 20.3029 2.59228ZM8.93173 10.9121C8.00725 10.9121 7.24645 9.9382 7.24645 8.75453C7.24645 7.57085 8.00725 6.59692 8.93173 6.59692C9.85621 6.59692 10.617 7.57085 10.617 8.75453C10.617 9.9382 9.85621 10.9121 8.93173 10.9121ZM15.1684 10.8996C14.2525 10.8996 13.4989 9.91996 13.4989 8.72908C13.4989 7.5382 14.2525 6.55852 15.1684 6.55852C16.0842 6.55852 16.8378 7.5382 16.8378 8.72908C16.8378 9.91996 16.0842 10.8996 15.1684 10.8996Z"/>
                            </svg>
                            <svg onClick={() => window.open('https://twitter.com/AmoDeosNft', '_blank')} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.4569 0H4.5431C2.03848 0 0 2.03848 0 4.5468V15.4532C0 17.9615 2.03848 20 4.5431 20H15.4569C17.9615 20 20 17.9615 20 15.4532V4.5431C20 2.03848 17.9615 0 15.4569 0ZM15.1091 7.94673C15.1128 8.05401 15.1165 8.1613 15.1165 8.27229C15.1165 11.6093 12.5823 15.4569 7.95043 15.4569C6.52978 15.4569 5.20533 15.0388 4.09175 14.3211C4.28783 14.3433 4.48761 14.3544 4.69108 14.3544C5.87125 14.3544 6.95523 13.9512 7.81724 13.2741C6.71476 13.2556 5.78616 12.5231 5.4643 11.5205C5.61968 11.5501 5.77877 11.5686 5.94155 11.5686C6.17092 11.5686 6.3929 11.5353 6.60377 11.4798C5.4532 11.2468 4.5838 10.2257 4.5838 9.00111C4.5838 8.99001 4.5838 8.98261 4.5838 8.97151C4.92416 9.16019 5.31262 9.27488 5.72327 9.28598C5.04624 8.83463 4.60599 8.06141 4.60599 7.18461C4.60599 6.72216 4.72808 6.28931 4.94636 5.91565C6.18572 7.44358 8.04292 8.44987 10.1369 8.55346C10.0925 8.36848 10.0703 8.1761 10.0703 7.98002C10.0703 6.58528 11.1987 5.4532 12.5897 5.4532C13.3111 5.4532 13.966 5.76027 14.4247 6.25231C14.9981 6.13762 15.5383 5.92675 16.0266 5.63818C15.838 6.22642 15.4384 6.72216 14.9168 7.03663C15.4273 6.97373 15.912 6.83685 16.3633 6.63707C16.0266 7.14391 15.6012 7.58787 15.1091 7.94673Z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                : null
            }
        </header>
    )
}

type CurrentLinkProps = {
    to: string,
    children: string
}

const CurrentLink: FC<CurrentLinkProps> = ({to, children}) => {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname })
    return (
        <Link
            className={
                isActive
                ? classes.link + ' ' + classes.active
                : classes.link
            }
            to={to}
        >
            {children}
        </Link>
    )
}

export default Navbar