import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { FC, useContext, useRef, useState } from 'react'
import { AppContext } from '../../../AppContext'
import { CURRENCY_NAME } from '../../../constants/staking'
import { useMobileDevice } from '../../../hooks/useDevice'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import { classBuilder } from '../../../utils/general'
import { Paragraph, ParagraphSize, ParagraphWeight } from '../../text/paragraph/Paragraph'
import classes from './styles.module.css'

type WalletButtonProps = {
  size: WalletButtonSize,
  color?: WalletButtonColor
}

export enum WalletButtonSize {
  Medium = 'medium',
  Inline = 'inline'
}

export enum WalletButtonColor {
  Default = 'default',
  Contrast = 'contrast'
}

const WalletButton: FC<WalletButtonProps> = (props) => {

  const { publicKey, disconnect } = useWallet()
  const [dropdown, setDropdown] = useState(false)
  const { setVisible } = useWalletModal()

  const showWalletModal = () => setVisible(true)
  const showDropdown = () => {
    setDropdown(prev => !prev)
  }

  const dropdownRef = useRef(null)

  useOutsideClick(dropdownRef, showDropdown)

  const isMobile = useMobileDevice()

  const { balance } = useContext(AppContext)
  
  return (
    <button ref={dropdown ? dropdownRef : null} onClick={publicKey ? showDropdown : showWalletModal} className={classBuilder(classes, [{ button: true }, props.size, (props.color || WalletButtonColor.Default)])}>
      <div className={classes.svg_container}>
        <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.6002 0.200275C1.28422 0.200275 0.200195 1.2843 0.200195 2.60028V15.4003C0.200195 16.7259 1.2746 17.8003 2.6002 17.8003H16.2002C17.0842 17.8003 17.8002 17.0843 17.8002 16.2003V5.00028C17.8002 4.11628 17.0842 3.40028 16.2002 3.40028H9.80019H2.6002C2.14817 3.40028 1.8002 3.0523 1.8002 2.60028C1.8002 2.14825 2.14817 1.80028 2.6002 1.80028H14.6002C14.7062 1.80177 14.8115 1.78219 14.9098 1.74266C15.0082 1.70313 15.0977 1.64444 15.1732 1.57C15.2487 1.49557 15.3087 1.40687 15.3496 1.30906C15.3905 1.21126 15.4116 1.1063 15.4116 1.00028C15.4116 0.894255 15.3905 0.789292 15.3496 0.691487C15.3087 0.593681 15.2487 0.504983 15.1732 0.430547C15.0977 0.356112 15.0082 0.297423 14.9098 0.257892C14.8115 0.218361 14.7062 0.198776 14.6002 0.200275H2.6002ZM14.6002 9.00028C15.4842 9.00028 16.2002 9.71628 16.2002 10.6003C16.2002 11.4843 15.4842 12.2003 14.6002 12.2003C13.7162 12.2003 13.0002 11.4843 13.0002 10.6003C13.0002 9.71628 13.7162 9.00028 14.6002 9.00028Z"/>
        </svg>
      </div>
      {
        publicKey
        ?
        <>
        {publicKey.toString().slice(0, (isMobile ? 3 : 5)) + '...' + publicKey.toString().slice(isMobile ? -3 : -5)}
        <div className={classes.svg_container + ' ' + classes.svg_arrow}>
          <svg viewBox='0 0 8 12' xmlns="http://www.w3.org/2000/svg">
            <path d="M4.77215 5.70181L0.951148 9.52281C0.537148 9.93681 0.537148 10.6088 0.951148 11.0228C1.36515 11.4368 2.03715 11.4368 2.45115 11.0228L7.06515 6.40881C7.45615 6.01781 7.45615 5.38481 7.06515 4.99481L2.45115 0.380812C2.03715 -0.0331877 1.36515 -0.0331878 0.951148 0.380812C0.537148 0.794812 0.537148 1.46681 0.951148 1.88081L4.77215 5.70181Z"/>
          </svg>
        </div>
        </>
        :
        <>
          Connect Wallet
          {
            props.size === 'medium' ?
            <div className={classes.svg_container}>
              <svg viewBox='0 0 24 24' xmlns="http://www.w3.org/2000/svg">
                <path d="M2 11C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13L2 11ZM22.7071 12.7071C23.0976 12.3166 23.0976 11.6834 22.7071 11.2929L16.3431 4.92893C15.9526 4.53841 15.3195 4.53841 14.9289 4.92893C14.5384 5.31946 14.5384 5.95262 14.9289 6.34315L20.5858 12L14.9289 17.6569C14.5384 18.0474 14.5384 18.6805 14.9289 19.0711C15.3195 19.4616 15.9526 19.4616 16.3431 19.0711L22.7071 12.7071ZM2 13L22 13V11L2 11L2 13Z"/>
              </svg>
            </div>
            : null
          }
        </>
      }
      {
        publicKey && dropdown
        ?
        <div onClick={(e) => e.stopPropagation()} className={classes.dropdown}>
          <ul>
            <li>
              <svg viewBox="0 0 16 16" fill="#181C25" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.68646 3.2998C8.89881 3.2998 9.07294 3.33971 9.20885 3.41952C9.35325 3.49135 9.47217 3.59112 9.5656 3.71882C9.65904 3.83854 9.73548 3.97821 9.79494 4.13783C9.8544 4.29746 9.90537 4.46107 9.94784 4.62868C10.2876 5.92164 10.5679 6.98713 10.7888 7.82516C11.0096 8.66319 11.1837 9.34159 11.3112 9.86037C11.4386 10.3712 11.5278 10.7503 11.5787 10.9977C11.6297 11.2451 11.6552 11.4167 11.6552 11.5125C11.6552 11.6242 11.6254 11.7 11.566 11.7399C11.515 11.7799 11.4301 11.7998 11.3112 11.7998H10.6613C10.2961 11.7998 10.0031 11.7599 9.7822 11.6801C9.56985 11.5923 9.40421 11.4686 9.28529 11.309C9.17487 11.1493 9.09418 10.9578 9.04321 10.7343C8.99225 10.5029 8.94977 10.2435 8.9158 9.95614H6.69882L6.27836 11.6083C6.23589 11.736 6.13821 11.7998 5.98531 11.7998H5.31003C5.17412 11.7998 5.03397 11.7878 4.88957 11.7639C4.74517 11.732 4.61351 11.6841 4.49459 11.6202C4.38416 11.5564 4.29073 11.4766 4.21428 11.3808C4.13783 11.277 4.09961 11.1533 4.09961 11.0097C4.09961 10.8341 4.15907 10.5468 4.27799 10.1477C4.39691 9.74863 4.54555 9.29769 4.72393 8.79488C4.9108 8.28408 5.11466 7.75333 5.33551 7.20262C5.56485 6.64394 5.7857 6.11718 5.99805 5.62234C6.21041 5.1275 6.40153 4.69253 6.57141 4.31741C6.74979 3.94229 6.88569 3.67891 6.97913 3.52727C7.04708 3.41553 7.10654 3.35168 7.15751 3.33572C7.21697 3.31178 7.32314 3.2998 7.47604 3.2998H8.68646ZM7.15751 8.22023H8.62275L8.08762 5.05966L7.15751 8.22023Z"/>
                  <mask id="path-2-inside-1_196_2835" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"/>
                  </mask>
                <path d="M10.5 8C10.5 9.38071 9.38071 10.5 8 10.5V18.5C13.799 18.5 18.5 13.799 18.5 8H10.5ZM8 5.5C9.38071 5.5 10.5 6.61929 10.5 8H18.5C18.5 2.20101 13.799 -2.5 8 -2.5V5.5ZM5.5 8C5.5 6.61929 6.61929 5.5 8 5.5V-2.5C2.20101 -2.5 -2.5 2.20101 -2.5 8H5.5ZM8 10.5C6.61929 10.5 5.5 9.38071 5.5 8H-2.5C-2.5 13.799 2.20101 18.5 8 18.5V10.5ZM12 8C12 10.2091 10.2091 12 8 12V20C14.6274 20 20 14.6274 20 8H12ZM8 4C10.2091 4 12 5.79086 12 8H20C20 1.37258 14.6274 -4 8 -4V4ZM4 8C4 5.79086 5.79086 4 8 4V-4C1.37258 -4 -4 1.37258 -4 8H4ZM8 12C5.79086 12 4 10.2091 4 8H-4C-4 14.6274 1.37258 20 8 20V12Z" mask="url(#path-2-inside-1_196_2835)"/>
              </svg>
              <Paragraph size={ParagraphSize.ExtraSmall} defaultLineHeight>My balance:</Paragraph>
              <Paragraph size={ParagraphSize.ExtraSmall} defaultLineHeight>
                <Paragraph size={ParagraphSize.ExtraSmall} weight={ParagraphWeight.SemiBold} defaultLineHeight span>
                  {(Math.floor(balance * 100) / 100).toString()}&nbsp;
                </Paragraph> 
              {CURRENCY_NAME}
              </Paragraph>
            </li>
            <hr/>
            <li onClick={disconnect}>
              <svg viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.66628 0.599609C1.07801 0.599609 0.599609 1.07801 0.599609 1.66628V12.3329C0.599609 12.9212 1.07801 13.3996 1.66628 13.3996H9.13294C9.72121 13.3996 10.1996 12.9212 10.1996 12.3329V7.53294H5.93294C5.63854 7.53294 5.39961 7.29401 5.39961 6.99961C5.39961 6.70521 5.63854 6.46628 5.93294 6.46628H10.1996V1.66628C10.1996 1.07801 9.72121 0.599609 9.13294 0.599609H1.66628ZM10.1996 6.46628V7.53294H13.1788L11.9559 8.75586C11.9047 8.805 11.8638 8.86387 11.8357 8.929C11.8075 8.99414 11.7926 9.06424 11.7919 9.13519C11.7912 9.20615 11.8046 9.27653 11.8314 9.34223C11.8583 9.40792 11.8979 9.46761 11.9481 9.51778C11.9983 9.56796 12.058 9.60762 12.1237 9.63444C12.1894 9.66126 12.2597 9.6747 12.3307 9.67398C12.4017 9.67326 12.4717 9.65838 12.5369 9.63023C12.602 9.60208 12.6609 9.56121 12.71 9.51003L14.8434 7.37669C14.9433 7.27667 14.9995 7.14103 14.9995 6.99961C14.9995 6.85818 14.9433 6.72255 14.8434 6.62253L12.71 4.48919C12.6603 4.4381 12.6009 4.39749 12.5352 4.36976C12.4696 4.34203 12.399 4.32774 12.3277 4.32773C12.2216 4.32776 12.118 4.35943 12.03 4.41869C11.9419 4.47795 11.8736 4.56211 11.8337 4.66041C11.7938 4.75871 11.7841 4.86668 11.8058 4.97053C11.8276 5.07437 11.8798 5.16936 11.9559 5.24336L13.1788 6.46628H10.1996Z"/>
              </svg>
              <Paragraph size={ParagraphSize.ExtraSmall} defaultLineHeight>Disconnect wallet</Paragraph>
            </li>
          </ul>
        </div>
        : null
      }
    </button>
  )
}

export default WalletButton