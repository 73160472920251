import { useState, useEffect } from "react"
import { unixTimestamp } from "../utils/general"

export const useUnixTimestamp = () => {

    const [timestamp, setTimestamp] = useState<number>(unixTimestamp())

    useEffect(() => {
      const interval = setInterval(() => setTimestamp(prev => prev + 10), 10 * 1000)
    
      return () => clearInterval(interval)
    }, [])
    
    return timestamp

}