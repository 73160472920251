import { BN, web3 } from '@project-serum/anchor'
import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import { TOKEN_PROGRAM_ID } from '@project-serum/anchor/dist/cjs/utils/token'
import { AnchorWallet } from '@solana/wallet-adapter-react'
import { DEFAULT_LOCKUP_LENGTH, STAKING_CPI_SIGNER, STAKING_FEE_RECEIVER, STAKING_POOL_ACCOUNT } from '../../constants/staking'
import { UnstakedNft, StakedNft } from '../../types/staking/nfts'
import { metaplexClient, userProvider } from '../../utils/solana'
import { userStakingProgram } from '../../utils/local/staking'
import { MASTER_CURRENCY_ACCOUNT, MASTER_PROGRAM_ID } from '../../constants/master'
import { getOrCreateUser } from '../master/programSimpleTransactions'

const systemProgram = web3.SystemProgram.programId
const masterProgram = MASTER_PROGRAM_ID
const tokenMetadataProgram = metaplexClient.programs().getTokenMetadata().address
const tokenProgram = TOKEN_PROGRAM_ID

const feeReceiver = STAKING_FEE_RECEIVER
const pool = STAKING_POOL_ACCOUNT
const cpiSigner = STAKING_CPI_SIGNER
const currency = MASTER_CURRENCY_ACCOUNT

export type InstructionBuilderResponse = {
    instructions: web3.TransactionInstruction[],
    responses?: any[]
}

interface StakeInstructionBuilderResponse extends InstructionBuilderResponse {
    responses: StakeResponse[]
}

export type StakeResponse = {
    stake: web3.PublicKey,
    mint: web3.PublicKey
}


export const stakeNft: (wallet: AnchorWallet, nfts: UnstakedNft[]) => Promise<StakeInstructionBuilderResponse> = async (wallet, nfts) => {
    const program = userStakingProgram(userProvider(wallet))

    const instructions: web3.TransactionInstruction[] = []
    const responses: StakeResponse[] = []

    await Promise.all(nfts.map(async (nft) => {
        const [stake] = web3.PublicKey.findProgramAddressSync(
            [utf8.encode("stake"), nft.mint.toBuffer()],
            program.programId
        )

        const instruction = await program.methods.stakeItemV2({
            lockup: new BN(DEFAULT_LOCKUP_LENGTH),
            merkleProof: null
        }).accounts({
            systemProgram,
            signer: wallet.publicKey,
            stake,
            item: {
                itemAccount: nft.token,
                itemEdition: nft.masterEdition,
                itemMetadata: nft.metadata,
                itemMint: nft.mint,
                signer: wallet.publicKey,
                tokenMetadataProgram,
                tokenProgram
            },
            feeReceiver,
            pool
        }).instruction()

        instructions.push(instruction)
        responses.push({
            mint: nft.mint,
            stake
        })
    }))

    return {
        instructions,
        responses
    }
}

export const unstakeNft: (wallet: AnchorWallet, nfts: StakedNft[]) => Promise<InstructionBuilderResponse> = async (wallet, nfts) => {
    const program = userStakingProgram(userProvider(wallet))

    const instructions: web3.TransactionInstruction[] = []

    const { user, instruction } = await getOrCreateUser(wallet)

    if (instruction) instructions.push(instruction)

    await Promise.all(nfts.map(async (nft) => {
        const instruction = await program.methods.unstakeItemV2().accounts({
            masterProgram,
            systemProgram,
            signer: wallet.publicKey,
            stake: nft.stake,
            user,
            item: {
                itemAccount: nft.token,
                itemEdition: nft.masterEdition,
                itemMetadata: nft.metadata,
                itemMint: nft.mint,
                signer: wallet.publicKey,
                tokenMetadataProgram,
                tokenProgram
            },
            cpiSigner,
            currency,
            feeReceiver,
            pool
        }).instruction()

        instructions.push(instruction)
    }))

    return {
        instructions
    }
}

export const claimReward: (wallet: AnchorWallet, nfts: StakedNft[]) => Promise<InstructionBuilderResponse> = async (wallet, nfts) => {
    const program = userStakingProgram(userProvider(wallet))

    const instructions: web3.TransactionInstruction[] = []

    const { user, instruction } = await getOrCreateUser(wallet)

    if (instruction) instructions.push(instruction)

    await Promise.all(nfts.map(async (nft) => {
        const instruction = await program.methods.claimRewardV2().accounts({
            masterProgram,
            systemProgram,
            signer: wallet.publicKey,
            stake: nft.stake,
            user,
            cpiSigner,
            currency,
            feeReceiver,
            pool
        }).instruction()

        instructions.push(instruction)
    }))

    return {
        instructions
    }
}