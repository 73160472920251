import { FC } from 'react'
import { CURRENCY_NAME } from '../../../../constants/staking'
import WalletConnectButton, { WalletButtonColor, WalletButtonSize } from '../../../dynamic/wallet_button/WalletButton'
import { Paragraph, ParagraphSize } from '../../../text/paragraph/Paragraph'
import classes from './styles.module.css'
import { ReactComponent as Treasure } from './treasure.svg'

const ConnectWallet: FC = () => (
    <div className={classes.block}>
        <Treasure className={classes.image}/>
        <Paragraph size={ParagraphSize.Medium} center>
            Stake your Amo to earn {CURRENCY_NAME}!<br/>
            Start collecting trophies!
        </Paragraph>
        <WalletConnectButton color={WalletButtonColor.Contrast} size={WalletButtonSize.Medium}/>
    </div>
)

export default ConnectWallet