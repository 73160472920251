import { FC } from 'react'
import ConnectWallet from '../../components/static/staking_landing/connect_wallet/ConnectWallet'
import HowItWorks from '../../components/static/staking_landing/how_it_works/HowItWorks'
import RewardUsage from '../../components/static/staking_landing/reward_usage/RewardUsage'
import SloganAndRates from '../../components/static/staking_landing/slogan_and_rates/SloganAndRates'
import { Header, HeaderSize } from '../../components/text/header/Header'
import styles from './styles.module.css'

const StakingLanding: FC = () => {
  return (
    <section className={styles.landing}>
          <Header classes={styles.header} size={HeaderSize.Large}>Staking</Header>
          <SloganAndRates/>
          <ConnectWallet/>
          <HowItWorks/>
          <RewardUsage/>
    </section>
  )
}

export default StakingLanding