import { FC } from 'react'
import { Header, HeaderSize } from '../../components/text/header/Header'
import { Paragraph, ParagraphSize, ParagraphWeight } from '../../components/text/paragraph/Paragraph'
import { useMobileDevice } from '../../hooks/useDevice'
import styles from './styles.module.css'

const Manifesto: FC = () => {
  const isMobile = useMobileDevice()
  return (
    <section className={styles.manifesto}>
          <Header size={HeaderSize.Large}>The new era is coming{isMobile ? <br/> : ''}<Header size={HeaderSize.ExtraLarge} span>...</Header></Header>
          <Paragraph size={ParagraphSize.MediumSmall} weight={ParagraphWeight.Thin}>
          The new era is coming...<br/>
          You can ride on a wave or get under it.<br/>
          So join us and be on the wave!<br/>
          We are creating open communities where we:<br/>
          — Share anything<br/>
          — Make magic internet money.<br/>
          If you still need to realize what is happening here, we politely say GM.<br/>
          We are here to help you to be on topic.<br/>
          <br/>
          Together we can reward our homies and jump down the rabbit hole.<br/>
          We take you from a world created by the majority and belonging to the few to a world created by the minority and belonging to everyone.<br/>
          There is a beautiful web3 world.<br/>
          <br/>
          We <Paragraph span marker size={ParagraphSize.Medium}>evolve</Paragraph> together.<br/>
          We <Paragraph span marker size={ParagraphSize.Medium}>earn</Paragraph> together.<br/>
          We <Paragraph span marker size={ParagraphSize.Medium}>create</Paragraph> together.<br/>
          <br/>
          AmoDeos is more than a community. It's a family.<br/>
          So welcome to the Amo family!
          </Paragraph>
    </section>
  )
}

export default Manifesto