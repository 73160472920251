export type MasterProgram = {
  "version": "0.2.2",
  "name": "master",
  "instructions": [
    {
      "name": "initializeAuthorities",
      "accounts": [
        {
          "name": "authorities",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "updateAuthorities",
      "accounts": [
        {
          "name": "authorities",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "UpdateAuthoritiesData"
          }
        }
      ]
    },
    {
      "name": "initializeCurrencyIds",
      "accounts": [
        {
          "name": "currencyIds",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "id",
          "type": "u8"
        }
      ]
    },
    {
      "name": "initializeUidsV2",
      "accounts": [
        {
          "name": "uids",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "id",
          "type": "u8"
        }
      ]
    },
    {
      "name": "initializeUserV2",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "uids",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "updateBalanceV2",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stakingSigner",
          "isMut": false,
          "isSigner": true,
          "isOptional": true
        },
        {
          "name": "rafflesSigner",
          "isMut": false,
          "isSigner": true,
          "isOptional": true
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "UpdateBalanceV2Data"
          }
        }
      ]
    },
    {
      "name": "createCurrency",
      "accounts": [
        {
          "name": "currencyIds",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "CreateCurrencyData"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "authorities",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "authorities",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "currencyIds",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u8"
          },
          {
            "name": "ids",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "currency",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u16"
          },
          {
            "name": "decimals",
            "type": "u8"
          },
          {
            "name": "created",
            "type": "u64"
          },
          {
            "name": "burned",
            "type": "u64"
          },
          {
            "name": "symbol",
            "type": "string"
          },
          {
            "name": "name",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "uidsV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u8"
          },
          {
            "name": "ids",
            "type": {
              "vec": "u32"
            }
          }
        ]
      }
    },
    {
      "name": "userV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "balances",
            "type": {
              "vec": {
                "defined": "Balance"
              }
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "UpdateAuthoritiesData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "CreateCurrencyData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "decimals",
            "type": "u8"
          },
          {
            "name": "symbol",
            "type": "string"
          },
          {
            "name": "name",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "Balance",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u16"
          },
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "UpdateBalanceV2Data",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "increase",
            "type": "bool"
          },
          {
            "name": "id",
            "type": "u16"
          },
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "NotAllowed",
      "msg": "Transaction signer could not perform this action"
    },
    {
      "code": 6001,
      "name": "ImmutableAuthority",
      "msg": "This authority can not be removed"
    },
    {
      "code": 6002,
      "name": "AuthorityDuplicate",
      "msg": "This authority already exists"
    },
    {
      "code": 6003,
      "name": "InvalidCurrency",
      "msg": "Invalid currency provided"
    },
    {
      "code": 6004,
      "name": "NotEnoughBalance",
      "msg": "Not enough balance to spend"
    }
  ]
};

export const MASTER_IDL: MasterProgram = {
  "version": "0.2.2",
  "name": "master",
  "instructions": [
    {
      "name": "initializeAuthorities",
      "accounts": [
        {
          "name": "authorities",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "updateAuthorities",
      "accounts": [
        {
          "name": "authorities",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "UpdateAuthoritiesData"
          }
        }
      ]
    },
    {
      "name": "initializeCurrencyIds",
      "accounts": [
        {
          "name": "currencyIds",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "id",
          "type": "u8"
        }
      ]
    },
    {
      "name": "initializeUidsV2",
      "accounts": [
        {
          "name": "uids",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "id",
          "type": "u8"
        }
      ]
    },
    {
      "name": "initializeUserV2",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "uids",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "updateBalanceV2",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "stakingSigner",
          "isMut": false,
          "isSigner": true,
          "isOptional": true
        },
        {
          "name": "rafflesSigner",
          "isMut": false,
          "isSigner": true,
          "isOptional": true
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "UpdateBalanceV2Data"
          }
        }
      ]
    },
    {
      "name": "createCurrency",
      "accounts": [
        {
          "name": "currencyIds",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "currency",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "data",
          "type": {
            "defined": "CreateCurrencyData"
          }
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "authorities",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "authorities",
            "type": {
              "vec": "publicKey"
            }
          }
        ]
      }
    },
    {
      "name": "currencyIds",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u8"
          },
          {
            "name": "ids",
            "type": {
              "vec": "u16"
            }
          }
        ]
      }
    },
    {
      "name": "currency",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u16"
          },
          {
            "name": "decimals",
            "type": "u8"
          },
          {
            "name": "created",
            "type": "u64"
          },
          {
            "name": "burned",
            "type": "u64"
          },
          {
            "name": "symbol",
            "type": "string"
          },
          {
            "name": "name",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "uidsV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "id",
            "type": "u8"
          },
          {
            "name": "ids",
            "type": {
              "vec": "u32"
            }
          }
        ]
      }
    },
    {
      "name": "userV2",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u32"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          },
          {
            "name": "bump",
            "type": "u8"
          },
          {
            "name": "balances",
            "type": {
              "vec": {
                "defined": "Balance"
              }
            }
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "UpdateAuthoritiesData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "new",
            "type": "bool"
          },
          {
            "name": "pubkey",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "CreateCurrencyData",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "decimals",
            "type": "u8"
          },
          {
            "name": "symbol",
            "type": "string"
          },
          {
            "name": "name",
            "type": "string"
          }
        ]
      }
    },
    {
      "name": "Balance",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "id",
            "type": "u16"
          },
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "UpdateBalanceV2Data",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "increase",
            "type": "bool"
          },
          {
            "name": "id",
            "type": "u16"
          },
          {
            "name": "amount",
            "type": "u64"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "NotAllowed",
      "msg": "Transaction signer could not perform this action"
    },
    {
      "code": 6001,
      "name": "ImmutableAuthority",
      "msg": "This authority can not be removed"
    },
    {
      "code": 6002,
      "name": "AuthorityDuplicate",
      "msg": "This authority already exists"
    },
    {
      "code": 6003,
      "name": "InvalidCurrency",
      "msg": "Invalid currency provided"
    },
    {
      "code": 6004,
      "name": "NotEnoughBalance",
      "msg": "Not enough balance to spend"
    }
  ]
};
