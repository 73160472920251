import { useWallet } from '@solana/wallet-adapter-react'
import { createContext, FC, useEffect, useMemo, useState } from 'react'
import NftCard from '../../components/dynamic/staking/nft_card/NftCard'
import StakingButtons from '../../components/dynamic/staking/staking_buttons/StakingButtons'
import StakingFilter from '../../components/dynamic/staking/staking_filter/StakingFilter'
import UserStats from '../../components/dynamic/staking/user_stats/UserStats'
import HeaderAndRates from '../../components/static/staking/header_and_rates/HeaderAndRates'
import NoNfts, { NoNftsType } from '../../components/static/staking/no_nfts/NoNfts'
import { usePrefetching } from '../../hooks/usePrefetching'
import { useUnixTimestamp } from '../../hooks/useUnixTimestamp'
import { getFilteredNftsByUser } from '../../requests/staking/getNfts'
import { NftFilter, StakedNft, UnstakedNft } from '../../types/staking/nfts'
import { getRewardForTimestamps } from '../../utils/local/staking'
import classes from './styles.module.css'

export const TimeContext = createContext(0)

const Staking: FC = () => {

  const { publicKey } = useWallet()

  const [nfts, setNfts] = useState<(UnstakedNft | StakedNft)[]>([])

  const timestamp = useUnixTimestamp()

  const filters: NftFilter[] = [
    {
      status: undefined,
      label: "All NFTs"
    },
    {
      status: "staked",
      label: "Staked"
    },
    {
      status: "unstaked",
      label: "Unstaked"
    }
  ]

  const [filter, setFilter] = useState<NftFilter>(filters[0])

  const filteredNfts = useMemo<(UnstakedNft | StakedNft)[]>(() => {
    if (!nfts.length) return nfts
    let sorted = nfts
      .sort((a, b) => Number(b.name.split('#')[1]) - Number(a.name.split('#')[1]))
      .sort((a) => a.status === 'staked' ? -1 : 1)
      .sort((a, b) => a.status === 'staked' && b.status === 'staked' ? getRewardForTimestamps(b.stakeTimestamp, b.claimTimestamp, Date.now() / 1000) - getRewardForTimestamps(a.stakeTimestamp, a.claimTimestamp, Date.now() / 1000) : 0)
    if (!filter.status) return sorted
    else return sorted.filter(nft => nft.status === filter.status)
  }, [nfts, filter])

  const stakedNftsCount = useMemo<number>(() => {
    if (!nfts.length) return 0
    return nfts.filter(nft => nft.status === 'staked').length
  }, [nfts])

  const claimableReward = useMemo<number>(() => {
    if (!stakedNftsCount || stakedNftsCount === 0) return 0
    let staked = nfts.filter(nft => nft.status === 'staked') as StakedNft[]
    let reward = staked.reduce((reward, nft) => {
        reward += getRewardForTimestamps(nft.stakeTimestamp, nft.claimTimestamp, timestamp)
        return reward
    }, 0)
    return Math.floor(reward * 100) / 100
  }, [stakedNftsCount, timestamp, nfts])

  const [fetchNfts, areNftsLoading, nftsError] = usePrefetching(async () => {
    const nfts = await getFilteredNftsByUser(publicKey!)
    setNfts(nfts)
  })

  useEffect(() => {
    fetchNfts()
  // eslint-disable-next-line
  }, [])

  return (
    <TimeContext.Provider value={timestamp}>
      <section className={classes.staking}>
        <div className={classes.row}>
            <HeaderAndRates/>
            <UserStats claimable_reward={areNftsLoading ? undefined : claimableReward} total_staked={areNftsLoading ? undefined : stakedNftsCount}/>
        </div>
        <div className={classes.row}>
            <StakingFilter current={filter} filters={filters} setFilter={setFilter}/>
            <StakingButtons claimableReward={claimableReward} nfts={nfts} setNfts={setNfts}/>
        </div>
        {
          areNftsLoading ?
          <NoNfts type={NoNftsType.Loading}/> :
          nftsError ?
          <NoNfts type={NoNftsType.Global}/> :
          filteredNfts.length ?
          <div className={
            classes.grid + ' ' + 
            (filteredNfts.length >= 4 ? classes.four_items : filteredNfts.length >= 3 ? classes.three_items : filteredNfts.length >= 2 ? classes.two_items : '')
          }>
            {
              filteredNfts.length ?
              filteredNfts.map((nft, i) => 
                <NftCard key={i} nft={nft} setNfts={setNfts}/>
              )
              : null
            }
          </div> :
          nfts.length ?
          <NoNfts type={filter.status === 'staked' ? NoNftsType.Staked : NoNftsType.Unstaked}/> :
          <NoNfts type={NoNftsType.Global}/>
        }
      </section>
    </TimeContext.Provider>
  )
}

export default Staking