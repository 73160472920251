import { FC } from 'react'
import { classBuilder } from '../../../utils/general'
import classes from './styles.module.css'

type ParagraphProps = {
    size: ParagraphSize,
    center?: boolean,
    opacity?: ParagraphOpacity,
    spacing?: ParagraphLetterSpacing,
    defaultLineHeight?: boolean,
    weight?: ParagraphWeight,
    contrast?: boolean,
    classes?: string,
    span?: boolean,
    marker?: boolean,
    children: string | JSX.Element | (string | JSX.Element)[]
}

export enum ParagraphSize {
    Vast = 'vast',
    ExtraLarge = 'extra_large',
    Large = 'large',
    MediumLarge = 'medium_large',
    Medium = 'medium',
    MediumSmall = 'medium_small',
    Small = 'small',
    ExtraSmall = 'extra_small',
    Little = 'little'
}

export enum ParagraphOpacity {
    Half = 'half_opacity',
    Medium = 'sixty_percent_opacity',
    High = 'eighty_percent_opacity'
}

export enum ParagraphWeight {
    Thin = 'thin',
    SemiBold = 'semi_bold',
    Bold = 'bold'
}

export enum ParagraphLetterSpacing {
    Medium = 'medium_spacing'
}
  
export const Paragraph: FC<ParagraphProps> = ({ size, center, opacity, spacing, defaultLineHeight, weight, contrast, marker, span, children, classes: extraClasses}) => {
    const className = classBuilder(classes, [size, { center }, opacity, spacing, { default_line_height: defaultLineHeight }, weight, { contrast }, { marker }], extraClasses)
    
    return (
        span ? <span className={className}>{children}</span> : <p className={className}>{children}</p>
)}