import { web3 } from "@project-serum/anchor"
import { useAnchorWallet, useConnection, AnchorWallet } from "@solana/wallet-adapter-react"
import { useContext } from "react"
import { AppContext } from "../AppContext"
import { ModalState } from "../components/dynamic/modal/Modal"
import { NotificationItemStatus } from "../components/dynamic/notification/Notification"
import { InstructionBuilderResponse } from "../requests/staking/programSimpleTransactions"


export const useTransaction = (
    instructionBuilder: (wallet: AnchorWallet) => Promise<InstructionBuilderResponse>,
    successMessage: string,
    callback?: ((...callbackArgs: any[]) => Promise<void>) | ((...callbackArgs: any[]) => void),
    accessControl?: (...accessArgs: any[]) => boolean,
    responsesCallback?: ((responses: any[]) => Promise<void>) | ((responses: any[]) => void)
) => {

    const wallet = useAnchorWallet()
    const { connection } = useConnection()
    const { sendNotification, showModal, hideModal } = useContext(AppContext)

    const sendTransaction = async () => {
        try {

            if (!wallet || !connection) return

            if (accessControl && !accessControl()) return

            if (showModal) showModal({
                state: ModalState.Loading,
                message: 'Building transaction for you.'
            })

            const { instructions, responses } = await instructionBuilder(wallet)
            const transactions: web3.Transaction[] = []

            instructions.forEach((ix, i) => {
                let txIndex = Math.floor((i + 1) / 4)

                if (!transactions[txIndex]) {
                    transactions.push(new web3.Transaction().add(ix))
                } else {
                    transactions[txIndex].add(ix)
                }
            })

            const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash()

            transactions.forEach(tx => {
                tx.recentBlockhash = blockhash
                tx.feePayer = wallet.publicKey
            })

            if (showModal) showModal({
                state: ModalState.Static,
                header: 'Approve required',
                message: 'Please, approve transaction in your wallet to proceed.'
            })
            
            const signed = await wallet.signAllTransactions(transactions)

            if (showModal) showModal({
                state: ModalState.Loading,
                message: 'The transaction is in progress.'
            })

            await Promise.all(
                signed.map(async (tx) => {
                    const signature = await connection.sendRawTransaction(tx.serialize())
                    console.log('Tx signature: ', signature)
                    await connection.confirmTransaction({
                        blockhash,
                        lastValidBlockHeight,
                        signature
                    }, 'finalized')
                })
            )

            if (callback) {
                await callback()
            }

            if (responses && responsesCallback) {
                await responsesCallback(responses)
            }

            if (sendNotification) {
                sendNotification({
                    status: NotificationItemStatus.Success,
                    header: 'Congratulations!',
                    message: successMessage,
                })
            }

        } catch (e: any) {

            let message: string = 'Oh no, the unknown error occurred!'

            if (e && e.message) switch (e.message) {
                case "User rejected the request.":
                    message = 'Hey dude, you forgot to approve transaction.'
                    break;
            }

            if (sendNotification) {
                sendNotification({
                    status: NotificationItemStatus.Error,
                    header: 'Transaction failed',
                    message
                })
            }
        } finally {
            if (hideModal) hideModal()
        }
    }

    return sendTransaction
}