import { FC } from 'react'
import { CURRENCY_NAME, REWARD_RATES } from '../../../../constants/staking'
import { Header, HeaderSize } from '../../../text/header/Header'
import { Paragraph, ParagraphOpacity, ParagraphSize } from '../../../text/paragraph/Paragraph'
import classes from './styles.module.css'

const HeaderAndRates: FC = () => {
  return (
    <div className={classes.wrapper}>
      <Header size={HeaderSize.MediumLarge}>Staking</Header>
      <Paragraph classes={classes.gaps} size={ParagraphSize.MediumSmall} opacity={ParagraphOpacity.Half}>
        Each staked Amo will earn {REWARD_RATES[0].reward} {CURRENCY_NAME} per day at start. Spend your {CURRENCY_NAME} in secret shop.
      </Paragraph>
      <div className={classes.list}>
        {
          REWARD_RATES.map((rate, index) => (
            <Paragraph key={index} defaultLineHeight classes={classes.item} size={ParagraphSize.MediumSmall}>
              <Paragraph size={ParagraphSize.Large} span>{index === 0 ? '1' : <>&gt;{rate.days}</>}</Paragraph>
              &nbsp;{index === 0 ? 'day' : 'days'} =&nbsp;
              <Paragraph size={ParagraphSize.Large} span>{rate.reward}</Paragraph>
              &nbsp;{CURRENCY_NAME}
            </Paragraph>
          ))
        }
      </div>
    </div>
  )
}

export default HeaderAndRates