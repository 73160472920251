import { FC } from 'react'
import ContentBlock from '../../components/dynamic/community/ContentBlock'
import { Header, HeaderSize } from '../../components/text/header/Header'
import styles from './styles.module.css'

const Community: FC = () => {
  return (
    <section className={styles.community}>
          <Header size={HeaderSize.Large}>Community</Header>
          <ContentBlock header='Search' items={[
            {
              header: 'Alpha',
              content: 'Our best analysts will surprise you with their researches. If you want to join, come in and add value and get a lot more in return. Only mutual exchange.'
            },
            {
              header: 'Library',
              content: 'The place where you can learn all about the crypto and nft world. It has everything you need and much more. From the best to the best.'
            }
          ]}/>
          <ContentBlock header='Invest' items={[
            {
              header: 'Private equity fund',
              content: 'We invest with our community in early-stage crypto projects and help them grow and create products.'
            },
            {
              header: 'Gold utility',
              content: 'Raffle, staking, access to our platforms and much more.'
            },
            {
              header: 'Wondering more ?',
              content: 'Patience, we will surprise you gradually.'
            }
          ]}/>
          <ContentBlock header='Share' items={[
            {
              header: 'Chat',
              content: 'The place where our community share with experience and alpha information.'
            },
            {
              header: 'Events',
              content: 'Offline and Online events for community members.\nConferences. Parties. Hackathons.'
            },
            {
              header: 'Clothing brand',
              content: 'Stylish. Comfortable. Minimalistic.'
            }
          ]}/>
    </section>
  )
}

export default Community