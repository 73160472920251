import { JsonMetadata, Metadata, NftWithToken } from '@metaplex-foundation/js'
import { web3 } from '@project-serum/anchor'
import { ITEM_VERIFIED_COLLECTION, ITEM_VERIFIED_CREATOR } from '../../constants/staking'
import { StakedNft, UnstakedNft } from '../../types/staking/nfts'
import { anonymousStakingProgram } from '../../utils/local/staking'
import { nftsClient } from '../../utils/solana'

export const getFilteredNftsByUser = async (user: web3.PublicKey) => {
    const nfts = await nftsClient.findAllByOwner({
        owner: user
    })

    const filtered = nfts
        .filter(nft => nft.model === 'metadata')
        .filter(nft => nft.creators.some(c => c.verified && c.address.toBase58() === ITEM_VERIFIED_CREATOR.toBase58()))
        .filter(nft => nft.collection && nft.collection.verified && nft.collection.address.toBase58() === ITEM_VERIFIED_COLLECTION.toBase58()) as Metadata<JsonMetadata<string>>[]

    const loaded: (StakedNft | UnstakedNft)[] = []

    await Promise.allSettled(
        filtered.map(async (nft) => {
            try {
                const loadedNft = await nftsClient.findByMetadata({
                    metadata: nft.address,
                    tokenOwner: user
                }) as NftWithToken

                if (loadedNft.token.delegateAddress && loadedNft.token.delegateAmount && Number(loadedNft.token.delegateAmount.basisPoints) === 1) {
                    try {
                        const stake = await anonymousStakingProgram.account.stakeV2.fetch(loadedNft.token.delegateAddress)
                        const nftModel: StakedNft = {
                            status: 'staked',
                            claimTimestamp: Number(stake.claimTimestamp),
                            stakeTimestamp: Number(stake.timestamp),
                            unlockTimestamp: Number(stake.timestamp) + Number(stake.lockup),
                            name: loadedNft.name,
                            image: loadedNft.json?.image,
                            masterEdition: loadedNft.edition.address,
                            metadata: loadedNft.metadataAddress,
                            mint: loadedNft.address,
                            token: loadedNft.token.address,
                            stake: loadedNft.token.delegateAddress
                        }
                        loaded.push(nftModel)
                        return
                    } catch {}
                }
                
                const nftModel: UnstakedNft = {
                    status: 'unstaked',
                    name: loadedNft.name,
                    image: loadedNft.json?.image,
                    masterEdition: loadedNft.edition.address,
                    metadata: loadedNft.metadataAddress,
                    mint: loadedNft.address,
                    token: loadedNft.token.address
                }

                loaded.push(nftModel)
            } catch {}
        })
    )

    return loaded
}