import { FC } from 'react'
import { CURRENCY_NAME, REWARD_RATES } from '../../../../constants/staking'
import { Header, HeaderSize } from '../../../text/header/Header'
import { Paragraph, ParagraphSize } from '../../../text/paragraph/Paragraph'
import classes from './styles.module.css'

const SloganAndRates: FC = () => {
  return (
    <div className={classes.wrapper}>
        <Header size={HeaderSize.MediumSmall}>
          No shitcoins. Just {CURRENCY_NAME}.
        </Header>
        <div className={classes.rates}>
            {
              REWARD_RATES.map((rate, index) => (
                <Paragraph size={ParagraphSize.Small} key={index}>{index !== 0 ? <>&gt;</> : ''} {rate.days} {index === 0 ? 'day' : 'days'} = {rate.reward} {CURRENCY_NAME.toLowerCase()}</Paragraph>
              ))
            }
        </div>
    </div>
  )
}

export default SloganAndRates