import classes from './styles.module.css';
import { FC } from 'react'

type ListProps = {
    pointer?: ListPointer,
    splitter?: string,
    children: string | string[]
}

export enum ListPointer {
  Number = 'number',
  Dot = 'dot'
}

export const List: FC<ListProps> = (props) => {
  return (
    <ol className={classes.list}>
        {(typeof props.children === 'string' ? props.children : props.children.join('')).split(props.splitter || '\\n').map((item, index) => (
        <li key={index}>
          {props.pointer ? 
            ((props.pointer === ListPointer.Dot && '• ') 
            ||
            (props.pointer === ListPointer.Number && `${index + 1}. `)) 
          : null}
          {item}
        </li>
      ))}
    </ol>
  )
}