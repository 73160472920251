import { FC, Fragment } from 'react'
import { Header, HeaderSize } from '../../text/header/Header'
import { Paragraph, ParagraphSize, ParagraphWeight } from '../../text/paragraph/Paragraph'
import classes from './styles.module.css'

type ContentBlockItem = {
    header: string,
    content: string,
    splitter?: string,
}

type ContentBlockProps = {
    header: string,
    items: ContentBlockItem[]
}

const ContentBlock: FC<ContentBlockProps> = ({header, items}) => {
  return (
    <div className={classes.block}>
        <Header size={HeaderSize.Medium}>{header}</Header>
        {
            items.map(({header, content, splitter}, i) => (
                <Paragraph key={i} size={ParagraphSize.MediumSmall} weight={ParagraphWeight.Thin}>
                    <Paragraph size={ParagraphSize.MediumSmall} weight={ParagraphWeight.SemiBold} span>{header.toUpperCase()}</Paragraph><br/>
                    <>
                    {content.split(splitter || '\n').map((line, i) => (
                        <Fragment key={i}> 
                            {i > 0 ? <br/> : ''}
                            {line}
                        </Fragment>
                    ))}
                    </>
                </Paragraph>
            ))
        }
    </div>
  )
}

export default ContentBlock