import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react'
import { createContext, FC, PropsWithChildren, useReducer, useState } from 'react'
import Modal, { ModalLoadingProps, ModalStaticProps } from './components/dynamic/modal/Modal'
import Notification, { NotificationItem, NotificationItemWithId } from './components/dynamic/notification/Notification'
import { useRefreshableEffect } from './hooks/useRefreshableEffect'
import { Theme, useTheme } from './hooks/useTheme'
import { getUserBalance } from './requests/master/programSimpleTransactions'

type RemoveNotification = {
    action: NotificationAction.Remove,
    id: number
}

type AddNotification = {
    action: NotificationAction.Add,
    item: NotificationItem
}

enum NotificationAction {
    Add = 'add',
    Remove = 'remove'
}

type AppContextProps = {
    theme: string,
    setTheme?: (val: Theme) => Theme,
    balance: number,
    refreshBalance?: () => void,
    sendNotification?: (item: NotificationItem) => void,
    showModal?: (modal: ModalLoadingProps | ModalStaticProps) => void,
    hideModal?: () => void
}

export const AppContext = createContext<AppContextProps>({theme: Theme.Light, balance: 0})

export const AppProvider: FC<PropsWithChildren> = ({children}) => {

    const { publicKey } = useWallet()
    const wallet = useAnchorWallet()
    
    const [theme, setTheme] = useTheme()
    const [balance, setBalance] = useState<number>(0)

    const refreshBalance = useRefreshableEffect(async () => {
        if (!publicKey || !wallet) return
        const bal = await getUserBalance(wallet)
        setBalance(bal)
    }, [publicKey])

    const [notifications, manageNotifications] = useReducer((notifications: NotificationItemWithId[], manageNotifications: AddNotification | RemoveNotification) => {
        if (manageNotifications.action === 'add') return [...notifications, {...manageNotifications.item, id: Date.now()}]
        else return notifications.filter(n => n.id !== manageNotifications.id)
    }, [])

    const removeNotification = (id: number) => manageNotifications({
        action: NotificationAction.Remove,
        id
    })

    const sendNotification = (item: NotificationItem) => manageNotifications({
        action: NotificationAction.Add,
        item
    })

    const [modal, setModal] = useState<ModalLoadingProps | ModalStaticProps | undefined>()

    const showModal = (modal: ModalLoadingProps | ModalStaticProps) => setModal(modal)
    const hideModal = () => setModal(undefined)

    return (
        <AppContext.Provider value={{theme, setTheme, balance, refreshBalance, sendNotification, showModal, hideModal}}>
            {
                modal ? 
                <div className='modal'>
                    <Modal {...modal}/>
                </div> : null
            }
            <div className='notifications'>
                {notifications.map(n => <Notification {...n} key={n.id} remove={removeNotification}/>)}
            </div>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider